<template>
  <div class="px-4 py-4 space-y-6 container">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Appraisal
      <InfoTooltip
        text="Appraisal criteria help assess the quality of studies in your review."
        url="/docs/reviews.html#setting-up-appraisal-criteria"
      />
    </h3>
    <div class="space-y-3">
      <div class="flex gap-4">
        <h3 class="text-lg text-primary font-bold flex items-center gap-2">
          Peer review status
        </h3>
        <Switch
          :disabled="review.isPlanReadonly.value"
          :model-value="
            review.entity.value?.plan?.appraisalPlan
              .isPeerReviewStatusApplicable
          "
          @update:model-value="toggleStudiesAppraisalPeerReviewStatus"
        />
      </div>
      <div class="flex gap-4">
        <h3 class="text-lg text-primary font-bold flex items-center gap-2">
          Oxford level of evidence
        </h3>
        <Switch
          :disabled="review.isPlanReadonly.value"
          :model-value="
            review.entity.value?.plan?.appraisalPlan
              .isOxfordLevelOfEvidenceApplicable
          "
          @update:model-value="toggleAppraisalIsOxfordLevelOfEvidence"
        />
      </div>

      <AppraisalCriteria />
    </div>
  </div>
</template>

<script setup lang="ts">
import Switch from '@app/components/Global/Switch.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import AppraisalCriteria from './AppraisalCriteria.vue'
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'

const review = injectStrict(ReviewKey)

function toggleAppraisalIsOxfordLevelOfEvidence(value: boolean) {
  if (value) review.enableStudiesAppraisalOxfordLevelOfEvidence()
  else review.disableStudiesAppraisalOxfordLevelOfEvidence()
}

function toggleStudiesAppraisalPeerReviewStatus(value: boolean) {
  if (value) review.enableStudiesAppraisalPeerReviewStatus()
  else review.disableStudiesAppraisalPeerReviewStatus()
}
</script>
