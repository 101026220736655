<template>
  <div class="space-y-6">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Inclusion criteria (PICO-TS)
    </h3>
    <div class="space-y-4" data-navigation="Criteria">
      <div class="space-y-2">
        <h3 class="text-base font-bold">Patient, problem or population</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in patientCriteria"
            :key="'patient-criterion-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(InclusionCriterion.Patient, criterion)
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="addInclusionCriterion(InclusionCriterion.Patient, $event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-base font-bold">Intervention</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in interventionCriteria"
            :key="'intervention-criterion-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(
                  InclusionCriterion.Intervention,
                  criterion,
                )
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addInclusionCriterion(InclusionCriterion.Intervention, $event)
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-base font-bold">Comparison, control or comparator</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in comparisonControlComparator"
            :key="'comparison-control-comparator-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(
                  InclusionCriterion.ComparisonControlComparator,
                  criterion,
                )
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addInclusionCriterion(
                InclusionCriterion.ComparisonControlComparator,
                $event,
              )
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-base font-bold">Outcomes</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in outcomesCriteria"
            :key="'outcomes-criterion-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(InclusionCriterion.Outcomes, criterion)
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="addInclusionCriterion(InclusionCriterion.Outcomes, $event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
      <div class="space-y-2">
        <h3 class="text-base font-bold">Timing</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in timingCriteria"
            :key="'timing-criterion-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(InclusionCriterion.Timing, criterion)
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="addInclusionCriterion(InclusionCriterion.Timing, $event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-base font-bold">Study design</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesignCriteria"
            :key="'study-design-criterion-' + criterion"
            class="flex items-center text-sm gap-2 px-3 py-2 min-h-10 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="
                deleteInclusionCriterion(
                  InclusionCriterion.StudyDesign,
                  criterion,
                )
              "
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="
              addInclusionCriterion(InclusionCriterion.StudyDesign, $event)
            "
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'

const review = injectStrict(ReviewKey)

const loading = useLoading()
const snackbar = useSnackbar()

const patientCriteria = ref<string[]>([])
const interventionCriteria = ref<string[]>([])

const outcomesCriteria = ref<string[]>([])

const timingCriteria = ref<string[]>([])

const studyDesignCriteria = ref<string[]>([])
const comparisonControlComparator = ref<string[]>([])

watchEffect(() => {
  patientCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.patient ?? []

  interventionCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.intervention ?? []

  outcomesCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.outcomes ?? []

  timingCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.timing ?? []

  studyDesignCriteria.value =
    review.entity.value?.plan?.inclusionCriteria?.studyDesign ?? []

  comparisonControlComparator.value =
    review.entity.value?.plan?.inclusionCriteria?.comparisonControlComparator ??
    []
})

async function addInclusionCriterion(
  criterionType: InclusionCriterion,
  criterion: string,
) {
  loading.start()
  try {
    await review.addInclusionCriterion({
      criterion: criterion,
      criterionType: criterionType,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Criterion added successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `an error occured while adding criterion to ${{ criterionType }}, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteInclusionCriterion(
  criterionType: InclusionCriterion,
  criterion: string,
) {
  loading.start()
  try {
    await review.deleteInclusionCriterion({
      criterion: criterion,
      criterionType: criterionType,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Criterion deleted successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        `an error occured while deleting ${{ criterionType }}, please try again.`,
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
