<template>
  <div id="modal" />
  <div
    id="app-wrapper"
    class="bg-white dark:bg-[#103047] dark:text-white text-black"
  >
    <header class="sticky top-0 left-0 bg-white" style="z-index: 999999999">
      <div
        class="flex px-4 w-full relative justify-between h-16 border-b border-[#D9E4EA] items-center"
      >
        <div class="flex items-center gap-14 justify-self-start">
          <button
            class="flex gap-1.5 text-xl font-bold px-2 py-1 dark:text-white"
            @click="$router.push('/')"
          >
            <EvidenceIcon />
          </button>
        </div>
        <div id="header-middle" />
        <UserSettingsFloatingMenu />

        <div
          v-if="loading.isLoading.value"
          class="overflow-hidden absolute bottom-0 left-0 w-full h-1"
        >
          <div class="line absolute bg-primary/20 h-1"></div>
          <div class="absolute bg-primary h-1 inc"></div>
          <div class="absolute bg-primary h-1 dec"></div>
        </div>
      </div>
      <div
        v-if="changelog.isNewVersionAvailable.value"
        class="w-full bg-yellow-700 px-10 py-4 flex justify-between text-white"
      >
        <button
          class="underline"
          @click="
            () => {
              changelogModal?.show()
              changelog.acknowledgeUpdate()
            }
          "
        >
          New version available, click here for more details
        </button>
        <div class="flex gap-8">
          <button @click="changelog.acknowledgeUpdate">close</button>
          <button @click="changelog.disableUpdatesNotification">
            disable notification
          </button>
        </div>
      </div>
    </header>

    <div id="toolbar" class="z-[51] bg-white" />

    <main
      id="main"
      :class="[
        modalManager.modals.value.some((m) => m.isVisible)
          ? 'overflow-hidden'
          : 'overflow-auto',
      ]"
    >
      <slot />
    </main>
    <aside class="border-r border-[#D9E4EA] flex flex-col p-1 space-y-4">
      <div class="space-y-2">
        <RevManRouterLink
          v-for="routeName in [RouteName.PROJECTS, RouteName.USERS]"
          :key="routeName"
          :route-name="routeName"
        />
      </div>
      <hr />
      <div class="space-y-2">
        <a
          v-tooltip.right.light="'Documentation'"
          href="/docs/"
          target="_blank"
          class="rounded-lg hover:bg-black/5 p-3 block text-primary"
        >
          <QuestionMarkCircle class="w-[24px] h-[24px]" />
        </a>
        <RevManRouterLink :route-name="RouteName.SETTINGS" />
      </div>
    </aside>
    <div id="drawer" class="shadow-lg overflow-y-auto" />
  </div>
  <div
    id="off-screen"
    class="overflow-hidden absolute top-0 left-0 -z-50"
  ></div>
</template>
<script lang="ts" setup>
import useLoading from '../composables/use-loading'
import RevManRouterLink from '@app/components/Global/RevManRouterLink.vue'
import { RouteName } from '@app/router'
import UserSettingsFloatingMenu from '@app/views/Users/UserSettingsFloatingMenu.vue'
import { ref, onMounted } from 'vue'
import EvidenceIcon from '@app/components/Icons/EvidenceIcon.vue'
import useModalManager from '@app/components/Global/Modal/modal-manager'
import useChangelog from '@app/composables/use-changelog'
import type Modal from '@app/components/Global/Modal/Modal.vue'
import QuestionMarkCircle from '@app/components/Icons/QuestionMarkCircle.vue'
import useAiAssistant from '@app/composables/useAiAssistant'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'

const { init } = useAiAssistant()
const auth = injectStrict(AuthKey)
if (auth.user.value?.email) init()
const modalManager = useModalManager()
const loading = useLoading()
const changelogModal = ref<InstanceType<typeof Modal>>()
const isDarkMode = ref(false)

onMounted(() => {
  const activeTheme = localStorage.getItem('theme')
  if (!activeTheme || activeTheme === 'light') {
    document.body.classList.remove('dark')
    isDarkMode.value = false
  } else {
    document.body.classList.add('dark')
    isDarkMode.value = true
  }
})

const changelog = useChangelog()
</script>
