import { updateEndDateInPubmedQuery } from '@app/utils/updateEndDateInPubmedQuery'
import { Review } from '@core/domain/models/review.model'
import { Search } from '@core/domain/models/search.model'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '@core/domain/types/full-text-cannot-be-retrieved-screening-criterion.type'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { format } from 'date-fns'

type StatSummary = {
  exclusionSummarytext: string
  hits: number
  duplicated: number
  excluded: number
  included: number
}

export type SearchAndLimts = {
  empty: boolean
  searchNumber: number
  searchDate: string
  source: string
  personUndertakingSearch: string
  query: string
  filters: string
  searchDetails: string
  statSummary: StatSummary
  parentStudyId: number | undefined
}

export function generateReviewSearchsAndLimtsResults(
  review: Review,
  includeStats: boolean,
) {
  let searchIndex = 0
  const searchesResults: SearchAndLimts[] = []
  const _generateSearchResult = (search: Search, searchIndex: number) => {
    let empty = false
    let statSummary: StatSummary = {
      duplicated: 0,
      excluded: 0,
      exclusionSummarytext: '',
      hits: 0,
      included: 0,
    }
    const query = ['', null, undefined].includes(search.searchQuery)
      ? '-'
      : search.searchQuery!
    const personUndertakingSearch = search.personUndertakingSearch ?? '-'
    const searchDate = !search.searchDate
      ? '-'
      : format(new Date(search.searchDate), 'dd LLL yyyy')

    let searchDetails = '-'

    if (search.source.id === 'pubmed') {
      searchDetails = search.queryTranslation
        ? updateEndDateInPubmedQuery(search.queryTranslation)
        : '-'
    }
    if (includeStats) {
      if (search.studies.length === 0) {
        empty = true
      } else {
        statSummary = generateExclusionSummarytext(review, searchIndex)
      }
    }
    searchesResults.push({
      empty: empty,
      source: search.source.name,
      searchDate: searchDate,
      searchNumber: searchIndex,
      personUndertakingSearch: personUndertakingSearch,
      statSummary: statSummary,
      filters: search.filters ? search.filters : '-',
      query: query,
      searchDetails: searchDetails,
      parentStudyId: search.parentStudyId,
    })
    return searchesResults
  }

  const sortedSearchs = [...review.searches!]

  sortedSearchs
    .filter((c) => [ImportSourceType.DATABASE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchResult(search, searchIndex)
    })
  sortedSearchs
    .filter((c) => [ImportSourceType.OTHER_SOURCE].includes(c.source.type))
    .forEach((search) => {
      searchIndex += 1

      _generateSearchResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.HAND_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchResult(search, searchIndex)
    })

  sortedSearchs
    .filter((c) => c.source.type === ImportSourceType.CITATION_SEARCH)
    .forEach((search) => {
      searchIndex += 1
      _generateSearchResult(search, searchIndex)
    })

  return searchesResults
}

function generateExclusionSummarytext(
  review: Review,
  searchIndex: number,
): StatSummary {
  let exclusionSummarytext = ''
  const titleAndAbstractExclusionSummary: [{ k?: string; value?: number }] = [
    {},
  ]
  const fullTextExclusionSummary: [{ k?: string; value?: number }] = [{}]
  const hits = review.stats.searches[`search ${searchIndex}`]?.total
  const duplicated = review.stats.searches[`search ${searchIndex}`]?.duplicate
  const excluded =
    (review.stats?.searches?.[`search ${searchIndex}`]
      ?.titleAndAbstractExcludedScreeningTotal ?? 0) +
    (review.stats.searches[`search ${searchIndex}`]
      ?.fullTextExcludedScreeningTotal ?? 0)
  const included = review.stats.searches[`search ${searchIndex}`]?.included
  review.plan?.screeningPlan.titleAndAbstractCriteria
    ?.filter((criteria) => criteria !== 'Included')
    .map((k: string) => {
      const value =
        review.stats.searches?.[`search ${searchIndex}`]
          ?.titleAndAbstractExcludedScreening?.[k] ?? 0
      const i = titleAndAbstractExclusionSummary.findIndex((e) => e.k === k)
      if (i > -1) {
        titleAndAbstractExclusionSummary[i].value! += value
      } else {
        titleAndAbstractExclusionSummary.push({ k: k, value: value })
      }
    })

  review.plan?.screeningPlan.fullTextCriteria
    .concat(FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
    ?.filter((criteria) => criteria !== 'Included')
    .map((k: string) => {
      const value =
        review.stats.searches?.[`search ${searchIndex}`]
          ?.fullTextExcludedScreening?.[k] ?? 0
      const i = fullTextExclusionSummary.findIndex((e) => e.k === k)
      if (i > -1) {
        fullTextExclusionSummary[i].value! += value
      } else {
        fullTextExclusionSummary.push({ k: k, value: value })
      }
    })

  if ((review.plan?.screeningPlan.titleAndAbstractCriteria?.length ?? 0) > 0) {
    exclusionSummarytext += 'SCREENING ON TITLE-AND-ABSTRACT: ' + '\n'
    titleAndAbstractExclusionSummary
      .filter((e) => Object.keys(e).length !== 0)
      .forEach((exclusion) => {
        exclusionSummarytext += `${exclusion.k} (n = ${exclusion.value})` + '\n'
      })
    exclusionSummarytext += 'SCREENING ON FULL-TEXT:' + '\n'
    fullTextExclusionSummary
      .filter((e) => Object.keys(e).length !== 0)
      .forEach((exclusion) => {
        exclusionSummarytext += `${exclusion.k} (n = ${exclusion.value})` + '\n'
      })
  } else {
    fullTextExclusionSummary
      .filter((e) => Object.keys(e).length !== 0)
      .forEach((exclusion) => {
        exclusionSummarytext += `${exclusion.k} (n = ${exclusion.value})` + '\n'
      })
  }
  return {
    duplicated: duplicated,
    excluded: excluded,
    exclusionSummarytext: exclusionSummarytext,
    hits: hits,
    included: included,
  }
}
