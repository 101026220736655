<template>
  <div class="w-full h-full">
    <ReviewPreset v-lazy-show="activeStep === Step.reviewPreset" />
    <EligibilityCriteria
      v-lazy-show="activeStep === Step.eligibilityCriteria"
    />
    <Appraisal
      v-lazy-show="activeStep === Step.appraisal"
      data-navigation="Appraisal"
    />
    <PlanImportSources
      v-lazy-show="activeStep === Step.importSources"
      data-navigation="Import"
      :readonly="review.isPlanReadonly.value"
    />
  </div>
</template>

<script lang="ts" setup>
import ReviewPreset from './ReviewPreset/ReviewPreset.vue'
import EligibilityCriteria from './EligibilityCriteria.vue'
import Appraisal from './Appraisal/Appraisal.vue'
import PlanImportSources from './ImportSources/PlanImportSources.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'

enum Step {
  reviewPreset = 1,
  eligibilityCriteria = 2,
  appraisal = 3,
  importSources = 4,
}
withDefaults(
  defineProps<{
    activeStep?: Step
  }>(),
  {
    activeStep: 1,
  },
)
const review = injectStrict(ReviewKey)
</script>
