import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { ProjectsRepository } from '@infrastructure/datasource/projects.repository'
export class ProjectsService {
  constructor(private _projectsRepository: ProjectsRepository) {}
  async create(data: { name: string }) {
    if (!data.name) throw Error('name is required')
    return this._projectsRepository.create(data)
  }

  async find(): Promise<Project[]> {
    return this._projectsRepository.find()
  }
  async findById(projectId: number): Promise<Project> {
    return this._projectsRepository.findById(projectId)
  }

  async createReview(projectId: Id, name: string): Promise<void> {
    return this._projectsRepository.createReview(projectId, name)
  }

  async delete(projectId: Id): Promise<void> {
    return this._projectsRepository.delete(projectId)
  }

  async downloadPdfZip(projectId: Id, selectedReviewsIds: Id[]): Promise<Blob> {
    return this._projectsRepository.downloadPdfZip(
      projectId,
      selectedReviewsIds,
    )
  }

  async updateProjectName(projectId: Id, name: string): Promise<void> {
    this._projectsRepository.updateProjectName(projectId, name)
  }
  async updateContactForComplaintEmail(
    projectId: Id,
    email: string,
  ): Promise<void> {
    return this._projectsRepository.updateContactForComplaint(projectId, email)
  }

  async updateCslStyle(projectId: Id, cslStyle: string): Promise<void> {
    return this._projectsRepository.updateCslStyle(projectId, cslStyle)
  }

  async addMember(projectId: Id, userId: string): Promise<void> {
    return this._projectsRepository.addMember(projectId, userId)
  }

  async addExternalMember(
    projectId: Id,
    externalMemberName: string,
  ): Promise<void> {
    return this._projectsRepository.addExternalMember(
      projectId,
      externalMemberName,
    )
  }

  async removeMember(projectId: Id, userId: string): Promise<void> {
    return this._projectsRepository.removeMember(projectId, userId)
  }

  async removeExternalMember(
    projectId: Id,
    externalMemberId: string,
  ): Promise<void> {
    return this._projectsRepository.removeExternalMember(
      projectId,
      externalMemberId,
    )
  }

  async setReviewsPlanLocking(projectId: Id, useReviewPlanLocking: boolean) {
    return this._projectsRepository.setReviewsPlanLocking(
      projectId,
      useReviewPlanLocking,
    )
  }
}
