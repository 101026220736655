<template>
  <div class="p-10 space-y-4">
    <TextInput
      v-model="password"
      label="Password"
      type="password"
      placeholder="Enter you new password"
    />
    <TextInput
      v-model="repeatPassword"
      type="password"
      label="Repeat password"
      placeholder="Enter you new password again"
    />

    <button
      type="button"
      class="rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
      :class="{
        'hover:bg-primary/80': isPasswordCorrect,
        'bg-gray-400': !isPasswordCorrect,
      }"
      :disabled="!isPasswordCorrect"
      @click="changePassword"
    >
      Change password
    </button>
  </div>
</template>

<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { UsersServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { UsersService } from '@core/application/users.service'
import { computed, ref } from 'vue'

const usersService: UsersService = injectStrict(UsersServiceKey)

const snackbar = useSnackbar()
const loading = useLoading()

const password = ref('')
const repeatPassword = ref('')

const isPasswordCorrect = computed(
  () => password.value && password.value === repeatPassword.value,
)

async function changePassword() {
  loading.start()
  if (!isPasswordCorrect.value) {
    snackbar.error('Passwords do not match')
    loading.stop()
    return
  }
  try {
    await usersService.changePassword(password.value)
    snackbar.success('password changed successfully')
  } catch (e) {
    let errorMessage = 'Something went wrong'
    const error = e as {
      response: {
        data: {
          ui: {
            nodes: {
              attributes: { name: string }
              messages: { type: string; text: string }[]
            }[]
          }
        }
      }
    }
    console.log(error.response?.data)
    const passwordErrors = error.response?.data?.ui?.nodes
      ?.find((node) => node.attributes.name === 'password')
      ?.messages?.find((message) => message.type === 'error')
    if (passwordErrors) {
      errorMessage = passwordErrors.text
    }

    snackbar.error(errorMessage)
  } finally {
    loading.stop()
  }
}
</script>
