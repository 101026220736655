<template>
  <h3 class="text-lg text-primary font-bold flex items-center gap-2">
    IMDRF MDCE 2019 appraisal Criteria
    <Switch
      :disabled="review.isPlanReadonly.value"
      :model-value="
        review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable
      "
      @update:model-value="toggleAppraisalIsApplication"
    />
  </h3>

  <div
    v-if="review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable"
    class="space-y-4"
  >
    <div
      v-for="criterion in reviewPlanAppraisalCriteria"
      :key="criterion.id"
      class="space-y-1"
    >
      <div class="py-2 space-y-2">
        <p class="text-base font-bold text-slate-800">
          {{ criterion.id }} - Title: {{ criterion.title }}
        </p>
        <p class="text-base font-light text-slate-600">
          <span class="font-semibold">Question:</span> {{ criterion.question }}
        </p>
      </div>
      <div class="flex flex-wrap gap-2 items-center text-primary">
        <div
          v-for="(answer, index) in criterion.answers"
          :key="answer.id"
          class="flex items-center text-xs gap-1 pl-2 pr-2.5 py-1.5 rounded-full bg-blue-200 leading-none"
        >
          <div class="flex items-center gap-1">
            <div class="rounded-full bg-white/40 p-1.5">
              {{ criterion.id + (index + 1) }}
            </div>
            {{ answer.title }}
          </div>
          <button
            v-if="!review.isPlanReadonly.value"
            @click="deleteAnswer(answer.id, criterion.id)"
          >
            <XMarkIcon class="w-4 text-primary" />
          </button>
        </div>
        <FloatingTextInput
          v-if="!review.isPlanReadonly.value"
          @save="
            addAnswer({ appraisalCriterionId: criterion.id, answer: $event })
          "
        >
          <template #activator="{ show }">
            <button
              key=""
              class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
              @click.stop="show"
            >
              + Add answer
            </button>
          </template>
        </FloatingTextInput>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { ref, watchEffect } from 'vue'
import { AppraisalCriterion } from '@core/domain/models/appraisal-criterion.model'
import Switch from '@app/components/Global/Switch.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const snackbar = useSnackbar()
const loading = useLoading()

const review = injectStrict(ReviewKey)

const reviewPlanAppraisalCriteria = ref<AppraisalCriterion[]>([])

watchEffect(() => {
  reviewPlanAppraisalCriteria.value =
    review.entity.value.plan?.appraisalCriteria ?? []
})

async function addAnswer(data: {
  appraisalCriterionId: string
  answer: string
}) {
  loading.start()
  try {
    await review.addAppraisalCriterionAnswer(data)
    snackbar.show(SnackbarState.SUCCESS, 'answer added successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while adding answer, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteAnswer(answerId: string, appraisalCriterionId: string) {
  loading.start()
  try {
    await review.deleteAppraisalCriterionAnswer({
      answerId: answerId,
      appraisalCriterionId: appraisalCriterionId,
    })
    snackbar.show(SnackbarState.SUCCESS, 'answer deleted successfully')
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while deleting answer, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

function toggleAppraisalIsApplication(value: boolean) {
  if (value) review.enableStudiesAppraisalImdrfMdce2019()
  else review.disableStudiesAppraisal()
}
</script>
