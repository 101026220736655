<template>
  <InputScaffold
    :id="id"
    :error="computedError"
    :is-required="isRequired"
    :label="label"
  >
    <Datepicker
      v-model="value"
      model-type="yyyy-MM-dd"
      :format="format"
      :max-date="new Date()"
      auto-apply
      :enable-time-picker="false"
      :placeholder="placeholder"
      :clearable="false"
      input-class-name="!border-0 !outline-none !w-full"
      class="evidence-datepicker"
      :range="range"
      :multi-calendars="range"
    />
  </InputScaffold>
</template>

<script lang="ts" setup>
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker'
import { computed, ref } from 'vue'
import { useSettings } from '@app/composables/use-settings'
import InputScaffold from './InputScaffold.vue'
import { v4 as uuidv4 } from 'uuid'

const id = uuidv4()

const settings = useSettings()
const props = withDefaults(
  defineProps<{
    rules?: ((v: string | string[] | undefined) => boolean | string)[]
    label?: string
    class?: string
    placeholder: string
    error?: string
    isRequired?: boolean
    range?: boolean
  }>(),
  {
    class: '',
    rules: () => [],
    placeholder: '',
    label: '',
    error: '',
    isRequired: false,
    range: false,
  },
)

const value = defineModel<string | string[]>()
if (props.range) value.value = ['', '']

const format = computed(() => {
  return settings.dateFormat.value
})

const internalError = ref<string>()
const computedError = computed(() =>
  props.error ? props.error : internalError.value,
)

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function reset() {
  value.value = ''
  setTimeout(() => (internalError.value = ''), 0)
}

defineExpose({
  validate,
  reset,
})
</script>

<style>
.dp__menu_index {
  z-index: 999999999;
}
.evidence-datepicker .dp__icon.dp__input_icon.dp__input_icons {
  padding-left: 0;
}
.evidence-datepicker .dp__input_icon_pad {
  padding-left: 25px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
