<template>
  <div class="grid gap-4 w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
    <ReviewItemTypeCard
      title="Scholarly articles (Aggregated databases)"
      :sources="availableScholarlyArticlesAggregatedDatabasesSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
      @import-source-added="emit('importSourceAdded')"
    />

    <ReviewItemTypeCard
      title="Scholarly articles (Publisher-specific)"
      :sources="availableScholarlyArticlesPublisherSpecificSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Scholarly Articles (Systematic Review Databases)"
      :sources="availableScholarlyArticlesSystematicReviewDatabasesSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Clinical trials"
      :sources="availableClinicalTrialsSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Incident"
      :sources="availableIncidentSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Field safety notices"
      :sources="availableFieldSafetyNoticesSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Standards"
      :sources="availableStandardsSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <ReviewItemTypeCard
      title="Guidance"
      :sources="availableGuidanceSources"
      :is-plan-unlocked="isPlanUnlocked"
      @add-import-source="addImportSource"
    />

    <div
      class="border divide-y divide-black/10 rounded-lg bg-white border-black/30 flex flex-col items-center"
    >
      <div class="w-full felx items-center py-4 px-6 bg-black/10">
        <h3 class="text-2xl font-medium flex items-center gap-2">
          Other Sources
        </h3>
      </div>
      <ExpansionPanel class="w-full" :model-value="isOtherSourcesExpanded">
        <template #activator>
          <button
            v-tooltip.right="
              `<p>Other methods encompass sources where searches are not easily replicable, often due to varying search algorithms or personalized content delivery.</p><br/>
  <div class='flex gap-3'>
              <div>
    <div><strong>Search engine</strong> </div>
    <div><strong>Health organizations</strong> </div>
    <div><strong>Medical associations</strong> </div>
    <div><strong>Standards Organizations</strong> </div>
    <div><strong>Manufacturer websites</strong></div>
    <div><strong>Other online sources</strong> </div>
  </div>
  <div>
    <div>EXAMPLES: Google Scholar, Google</div>
    <div>EXAMPLES: WHO, NICE</div>
    <div>EXAMPLES: European Society of Cardiology</div>
    <div>EXAMPLES: ISO, IEC</div>
    <div> EXAMPLES: Medtronix, Baxters</div>
    <div> Any online source not included above</div>
    </div>
    </div>`
            "
            class="flex justify-between items-center w-full py-2 px-4"
            :class="{
              'hover:bg-black/5': isPlanUnlocked,
              'cursor-not-allowed': !isPlanUnlocked,
            }"
            @click="isPlanUnlocked ? togglePanel('other-sources') : ''"
          >
            <div>Other source</div>
            <InformationCircleIcon class="w-4 h-4 text-primary" />
          </button>
        </template>
        <template #default>
          <NewImportSourceBuilder
            :import-source-type="ImportSourceType.OTHER_SOURCE"
          />
        </template>
      </ExpansionPanel>
      <button
        v-if="!isCitationSearchActive"
        v-tooltip.right="
          `<p>Document records identified when screening the references of already identified records.</p>`
        "
        class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
        :class="{
          'hover:bg-black/5': isPlanUnlocked,
          'cursor-not-allowed': !isPlanUnlocked,
        }"
        @click="isPlanUnlocked ? addImportSource(citationSearch) : ''"
      >
        <div>Citation search</div>
        <InformationCircleIcon class="w-4 h-4 text-primary" />
      </button>

      <button
        v-if="!isHandSearchActive"
        v-tooltip.right="
          `<p>(not recommended) documents records that have not been captured through systematic searches</p>`
        "
        class="px-4 py-2 w-full text-left flex items-center justify-between gap-2"
        :class="{
          'hover:bg-black/5': isPlanUnlocked,
          'cursor-not-allowed': !isPlanUnlocked,
        }"
        @click="isPlanUnlocked ? addImportSource(handSearch) : ''"
      >
        <div>Hand search</div>
        <InformationCircleIcon class="w-4 h-4 text-primary" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { DevToolboxKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import ReviewItemTypeCard from './ReviewItemTypeCard.vue'
import { isUrlValid } from '@app/utils/urlValidation'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import NewImportSourceBuilder from './NewImportSourceBuilder.vue'

const emit = defineEmits<(e: 'importSourceAdded') => void>()

const builtInImportSources = useBuiltInImportSources()
const review = injectStrict(ReviewKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)

const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!
const citationSearch = builtInImportSources.findById(
  BuiltInImportSourceId.CITATION_SEARCH,
)!

const loading = useLoading()
const snackbar = useSnackbar()

const isPlanUnlocked = computed(() => !review.isPlanReadonly.value)

const response = await fetch('/customSources.json')
const customSources: {
  id: string
  name: string
  url: string
  description: string
  type: ImportSourceType
  iconUrl: string
  reviewItemsType: string
}[] = await response.json()

const activeExpansionPanel = ref<'sources' | 'other-sources' | ''>('')

const isOtherSourcesExpanded = computed(
  () => activeExpansionPanel.value === 'other-sources',
)

const availableScholarlyArticlesAggregatedDatabasesSources = computed(() => {
  const notEvidenceMetadataExtractionTechnologySources = customSources
    .filter(
      (source) => source.reviewItemsType === 'ARTICLE-AGGREGATED-DATABASES',
    )
    .map((source) => ({
      label: source.name,
      id: source.id,
      iconUrl: source.iconUrl,
      isEvidenceMetadataExtractionTechnology: false,
      url: source.url,
      description: source.description,
      type: source.type,
    }))
  const articleSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.name,
      id: BuiltInImportSourceId.PUBMED,
      iconUrl: '/sources-icons/pubmed.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.PUBMED)!
        .description,
    },
    {
      label: builtInImportSources.findById(
        BuiltInImportSourceId.GOOGLE_SCHOLAR,
      )!.name,
      id: BuiltInImportSourceId.GOOGLE_SCHOLAR,
      iconUrl: '/sources-icons/google-scholar.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.GOOGLE_SCHOLAR)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.GOOGLE_SCHOLAR)!
        .url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.GOOGLE_SCHOLAR,
      )!.description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!
        .name,
      id: BuiltInImportSourceId.OPEN_ALEX,
      iconUrl: '/sources-icons/openalex.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.OPEN_ALEX)!.url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.OPEN_ALEX,
      )!.description,
    },
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .name,
      id: BuiltInImportSourceId.DIMENSIONS_AI,
      iconUrl: '/sources-icons/dimensions-ai.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .type,
      url: builtInImportSources.findById(BuiltInImportSourceId.DIMENSIONS_AI)!
        .url,
      description: builtInImportSources.findById(
        BuiltInImportSourceId.DIMENSIONS_AI,
      )!.description,
    },
    ...(isCuttingEdge
      ? [
          {
            label: builtInImportSources.findById(BuiltInImportSourceId.PMC)!
              .name,
            id: BuiltInImportSourceId.PMC,
            iconUrl: '/sources-icons/pmc.png',
            isEvidenceMetadataExtractionTechnology: true,
            type: builtInImportSources.findById(BuiltInImportSourceId.PMC)!
              .type,
            url: builtInImportSources.findById(BuiltInImportSourceId.PMC)!.url,
            description: builtInImportSources.findById(
              BuiltInImportSourceId.PMC,
            )!.description,
          },
          {
            label: builtInImportSources.findById(
              BuiltInImportSourceId.EUROPE_PMC,
            )!.name,
            id: BuiltInImportSourceId.EUROPE_PMC,
            iconUrl: '/sources-icons/europe-pmc.png',
            isEvidenceMetadataExtractionTechnology: true,
            type: builtInImportSources.findById(
              BuiltInImportSourceId.EUROPE_PMC,
            )!.type,
            url: builtInImportSources.findById(
              BuiltInImportSourceId.EUROPE_PMC,
            )!.url,
            description: builtInImportSources.findById(
              BuiltInImportSourceId.EUROPE_PMC,
            )!.description,
          },
          ...notEvidenceMetadataExtractionTechnologySources,
        ]
      : []),
  ]

  return checkForAvailableSources(articleSources)
})

const availableScholarlyArticlesPublisherSpecificSources = computed(() => {
  const notEvidenceMetadataExtractionTechnologySources = customSources
    .filter((source) => source.reviewItemsType === 'ARTICLE-PUBLISHER-SPECIFIC')
    .map((source) => ({
      label: source.name,
      id: source.id,
      iconUrl: source.iconUrl,
      isEvidenceMetadataExtractionTechnology: false,
      url: source.url,
      description: source.description,
      type: source.type,
    }))

  const articleSources = [
    ...(isCuttingEdge
      ? [...notEvidenceMetadataExtractionTechnologySources]
      : []),
  ]

  return checkForAvailableSources(articleSources)
})

const availableScholarlyArticlesSystematicReviewDatabasesSources = computed(
  () => {
    const scholarlyArticlesSystematicReviewDatabasesSources = [
      {
        label: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!
          .name,
        id: BuiltInImportSourceId.COCHRANE,
        iconUrl: '/sources-icons/cochrane.png',
        isEvidenceMetadataExtractionTechnology: true,
        type: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!
          .type,
        url: builtInImportSources.findById(BuiltInImportSourceId.COCHRANE)!.url,
        description: builtInImportSources.findById(
          BuiltInImportSourceId.COCHRANE,
        )!.description,
      },
    ]

    return checkForAvailableSources(
      scholarlyArticlesSystematicReviewDatabasesSources,
    )
  },
)

const availableClinicalTrialsSources = computed(() => {
  const clinicalStudiesSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.name,
      id: BuiltInImportSourceId.ICTRP,
      iconUrl: '/sources-icons/ictrp.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.ICTRP)!
        .description,
    },
  ]

  return checkForAvailableSources(clinicalStudiesSources)
})

const availableIncidentSources = computed(() => {
  const incidentSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.name,
      id: BuiltInImportSourceId.MAUDE,
      iconUrl: '/sources-icons/maude.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.MAUDE)!
        .description,
    },
  ]

  return checkForAvailableSources(incidentSources)
})

const availableFieldSafetyNoticesSources = computed(() => {
  const fieldSafetyAndCorrectiveActionsSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.BFARM)!.name,
      id: BuiltInImportSourceId.BFARM,
      iconUrl: '/sources-icons/custom-source.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.BFARM)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.BFARM)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.BFARM)!
        .description,
    },
  ]

  return checkForAvailableSources(fieldSafetyAndCorrectiveActionsSources)
})

const availableStandardsSources = computed(() => {
  const normsSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.CENELEC)!.name,
      id: BuiltInImportSourceId.CENELEC,
      iconUrl: '/sources-icons/custom-source.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.CENELEC)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.CENELEC)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.CENELEC)!
        .description,
    },
  ]

  return checkForAvailableSources(normsSources)
})

const availableGuidanceSources = computed(() => {
  const guidanceSources = [
    {
      label: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!.name,
      id: BuiltInImportSourceId.MDCG,
      iconUrl: '/sources-icons/mdcg.png',
      isEvidenceMetadataExtractionTechnology: true,
      type: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!.type,
      url: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!.url,
      description: builtInImportSources.findById(BuiltInImportSourceId.MDCG)!
        .description,
    },
  ]

  return checkForAvailableSources(guidanceSources)
})

const isHandSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.HAND_SEARCH,
    ) ?? false
  )
})

const isCitationSearchActive = computed(() => {
  return (
    review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === BuiltInImportSourceId.CITATION_SEARCH,
    ) ?? false
  )
})

function checkForAvailableSources(
  sources: {
    label: string
    id: string
    iconUrl: string
    isEvidenceMetadataExtractionTechnology: boolean
    url?: string
    description?: string
    type: string
  }[],
) {
  const reviewImportSources =
    review.entity.value.plan?.importPlan.importSources ?? []

  return sources.filter(
    (source) =>
      !reviewImportSources.some(
        (reviewSource) => reviewSource.id === source.id,
      ),
  )
}

async function addImportSource(data: {
  id?: string
  name: string
  url?: string
  description?: string
  type: ImportSourceType
}) {
  if (
    data.type !== ImportSourceType.HAND_SEARCH &&
    data.type !== ImportSourceType.CITATION_SEARCH &&
    (!data?.url || !isUrlValid(data?.url ?? ''))
  ) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    await review.addCustomImportSourceToPlan({
      id: data.id,
      name: data.name,
      url: data.url ?? '',
      description: data.description,
      type: data.type,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'Import source successfully added to plan',
    )
    emit('importSourceAdded')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function togglePanel(panel: 'sources' | 'other-sources') {
  if (activeExpansionPanel.value === panel) {
    activeExpansionPanel.value = ''
  } else {
    activeExpansionPanel.value = panel
  }
}
</script>
