<template>
  <FloatingMenu
    ref="floatingMenu"
    placement="bottom-start"
    :arrow="false"
    :offset="[0, -10]"
  >
    <template #activator="{ isShown }">
      <div
        v-tooltip="'Add project member'"
        class="pt-1 pb-4 px-1 flex gap-1 items-center hover:cursor-pointer text-black"
        :class="{ 'bg-[#F2F6F8]': isShown, 'hover:brightness-110': !isShown }"
        @click="openMenu"
      >
        <UsersIcon class="fill-primary w-5 h-5" />
        <p
          class="font-medium text-base leading-4 tracking-tight whitespace-nowrap"
        >
          Internal member
        </p>
        <ChevronDownIcon
          class="w-4 h-4 stroke-[3] transition-all"
          :class="{
            'rotate-180': isShown,
          }"
        />
      </div>
    </template>
    <template #content>
      <div
        class="w-[620px] shadow-lg border-t-0 absolute bg-[#F2F6F8] space-y-2 border bored border-[#D9E4EA] dark:bg-[#103047]"
      >
        <div
          class="flex pt-2 pb-4 px-2 w-full h-full flex-col text-black gap-1.5"
        >
          <p>Add members</p>
          <GenericAutoComplete
            v-model="selected"
            :items="remainingUsers"
            :item-options="{
              displayProperty: 'email',
              filterProperties: ['email', 'firstName', 'lastName'],
              valueProperty: 'id',
            }"
            placeholder="Select a user to add as member"
            @update:model-value="addMember"
          >
            <template #item="{ firstName, lastName, email }">
              {{ email }}
              {{ firstName && lastName ? `(${firstName} ${lastName})` : '' }}
            </template>
          </GenericAutoComplete>
        </div>
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import useUsers from '@app/composables/use-users'
import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import UsersIcon from '@app/components/Icons/UsersIcon.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { Project } from '@core/domain/models/project.model'
import useProjects from '@app/views/Projects/use-projects'

const props = defineProps<{
  project: Project
}>()
const { addProjectMember } = useProjects()
const { users } = useUsers()
const floatingMenu = ref()

async function openMenu() {
  floatingMenu?.value?.show()
}

const selected = ref('')

async function addMember(userId?: unknown) {
  if (!userId || typeof userId !== 'string') return
  selected.value = ''
  if (!props.project.users?.some((u) => u.id === userId)) {
    await addProjectMember(props.project.id!, userId)
  }
  floatingMenu.value?.hide()
}

const remainingUsers = computed(() =>
  users.value.filter(
    (u) => !u.isDisabled && !props.project.users?.some((m) => u.id === m.id),
  ),
)
</script>
