import { Id } from '@core/domain/types/id.type'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import * as CLS from 'citeproc'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
type StudyObject = {
  type: string
  id: number
  title?: string
  abstract?: string
  'container-title'?: string
  author: string | Array<{ family: string; given: string }>
  issued?: {
    'date-parts': string[][]
  }
  volume?: string
  issue?: string
  page?: string
}

export async function generateCitation(
  studies: ReviewItem[],
  cslStyleName: string,
): Promise<{
  findByStudyId: (studyId: Id) => { inText: string; fullText: string }
}> {
  const citations: { [id: Id]: StudyObject } = {}
  for (const study of studies) {
    if (study.type === ReviewItemType.Article) {
      citations[study.id] = {
        type: 'study-journal',
        id: study.id,
        title: study.metadata?.title,
        abstract: study.metadata?.abstract
          ?.replaceAll(new RegExp(/<[^>]*>/gm), '')
          .replaceAll(/\s+/g, ' ')
          .trim()
          .replace(/^Abstract(.*?)/m, ''),
        'container-title': study.metadata?.journal,
        author: study.metadata?.authors?.map((au: string) => {
          const [familyName, givenName] = au.split(', ')
          return {
            family: familyName,
            given: givenName,
          }
        }),
        ...(study.metadata?.publishYear && {
          issued: {
            'date-parts': [[study.metadata?.publishYear]],
          },
        }),
        volume: study.metadata?.volumeNumber,
        issue: study.metadata?.issueNumber,
        page: study.metadata?.pagesNumber,
      }
    } else if (study.type === ReviewItemType.Incident) {
      citations[study.id] = {
        type: 'study-journal',
        id: study.id,
        title: study.metadata?.title,
        abstract: study.metadata?.summary,
        author: study.metadata?.reportNumber,
        ...(study.metadata?.reportDate && {
          issued: {
            'date-parts': [[study.metadata?.reportDate]],
          },
        }),
      }
    } else if (study.type === ReviewItemType.Guidance) {
      citations[study.id] = {
        type: 'study-journal',
        id: study.id,
        title: study.metadata?.title,
        author: study.metadata?.reference,
        ...(study.metadata?.publishYear && {
          issued: {
            'date-parts': [[study.metadata?.publishYear]],
          },
        }),
      }
    }
  }
  const locale = await fetch('/csl/locale/locales-en-US.xml').then((r) =>
    r.text(),
  )
  const style = await fetch(`/csl/styles/${cslStyleName}.csl`).then((r) =>
    r.text(),
  )

  const itemsIds = studies.map((a) => a.id)
  const citeproc = new CLS.Engine(
    {
      retrieveLocale: function () {
        return locale
      },
      retrieveItem: function (id: Id) {
        return citations[id]
      },
    },
    style,
  )
  citeproc.setOutputFormat('text')
  citeproc.updateItems(itemsIds)

  const [params, result] = await citeproc.makeBibliography()

  const citationClusterPromises = studies.map(async (study) => {
    const citationObject = citations[study.id]
    return citeproc.makeCitationCluster([citationObject])
  })
  const citationClusters = await Promise.all(citationClusterPromises)

  const studyIdToClusterMap: { [id: string]: unknown } = {}
  studies.forEach((study, index) => {
    studyIdToClusterMap[study.id] = citationClusters[index]
  })

  return {
    findByStudyId(studyId: Id) {
      const index = (params.entry_ids as [[string]]).findIndex(
        ([id]: [string]) => parseInt(id) === studyId,
      )
      return {
        fullText: result[index],
        inText: (studyIdToClusterMap[studyId] as string) ?? 'ref',
      }
    },
  }
}
