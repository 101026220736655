<template>
  <div class="flex-grow h-full">
    <div class="px-4 text-lg">
      The goal is to identify the following information concerning
      <input
        v-model="deviceName"
        type="text"
        class="flex-1 pl-1 focus:outline-none border-b-2 min-w-[480px] py-2 transition-colors"
        :class="[isFocused ? 'border-black' : 'border-black/30']"
        placeholder="Enter device type and press enter"
        @keydown.enter="applyPreset"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ref } from 'vue'
const loading = useLoading()

const review = injectStrict(ReviewKey)
const isFocused = ref()

function generatePreset(deviceName: string) {
  return [
    {
      label: 'Clinical Performance and Benefits',
      question:
        ' Does the record report any clinical performance or benefit of ' +
        deviceName +
        '?',

      order: 1,
    },
    {
      label: 'Underperformance Reports',
      question:
        'Does the record report any underperformance of ' + deviceName + '?',
      order: 2,
    },
    {
      label: 'Performance Deterioration Over Time',
      question:
        'Does the record report any deterioration in the performance of ' +
        deviceName +
        ' over time?',
      order: 3,
    },

    {
      label: 'Hazardous Situations or Risks',
      question:
        'Does the record report any hazard or hazardous situation or risk associatd to the use of ' +
        deviceName +
        '? If yes, which ones?',
      order: 4,
    },

    {
      label: 'Device Malfunctions',
      question: 'Does the record report any malfunction of ' + deviceName + '?',
      order: 5,
    },

    {
      label: 'Expected Undesirable Side Effects',
      question:
        'Does the record report any expected undesirable side effects for ' +
        deviceName +
        '?',
      order: 6,
    },

    {
      label: 'Unexpected Undesirable Side Effects',
      question:
        'Does the record report any unexpected undesirable side effects for ' +
        deviceName +
        '?',
      order: 7,
    },
    {
      label: 'User Errors',
      question: 'Does the record report any use error with ' + deviceName + '?',
      order: 8,
    },
    {
      label: 'Usability Issues',
      question:
        'Does the record report any issue with the usability of ' +
        deviceName +
        '?',
      order: 9,
    },
    {
      label: 'Off-label Usage',
      question:
        'Does the record report any off-label use of ' + deviceName + '?',
      order: 10,
    },
    {
      label: 'Device Misuse',
      question: 'Does the record report any misuse of ' + deviceName + '?',
      order: 11,
    },
    {
      label: 'Information Material Inadequacies',
      question:
        'Does the record report any inadequacy in the information material of ' +
        deviceName +
        '?',
      order: 12,
    },
    {
      label: 'Performance or Safety Issues in Sub-populations',
      question:
        'Does the record highlight performance or safety issues of ' +
        deviceName +
        ' in any sub-population ?',
      order: 13,
    },
  ]
}
const deviceName = ref()

async function applyPreset() {
  const presets = generatePreset(deviceName.value)

  try {
    loading.start()
    await review.addMultipleAttributesStructure(review.entity.value.id, presets)
  } catch (error) {
    console.error('Error adding scope field:', error)
  } finally {
    loading.stop()
  }
}
</script>
