<template>
  <DropZoneFileUpload
    :accept-multiple-files="true"
    :accepted-file-types="['.ris']"
    @files-dropped="addCitationFiles"
  >
    <template
      #default="{ hasFileDraggedOver, hasFileOverWindow, triggerFilePicker }"
    >
      <div
        class="w-full flex justify-between items-center rounded-lg border-dashed border-2 bg-white border-primary/50 px-8 py-4 space-y-2"
        :class="{
          '!border-primary': hasFileDraggedOver || hasFileOverWindow,
          '!bg-primary/5': hasFileOverWindow && !hasFileDraggedOver,
          '!bg-primary/10': hasFileDraggedOver,
        }"
      >
        <div class="space-y-2">
          <h2 class="font-medium text-sm text-primary leading-none">
            Add RIS files <span v-if="isRequired" class="text-red-600">*</span>
            <div class="text-xs text-primary/60">
              Drag/drop RIS of articles included from search
            </div>
          </h2>
          <div v-if="citationFiles.length > 0" class="flex gap-2 flex-wrap">
            <div
              v-for="(citationFile, index) in citationFiles"
              :key="citationFile.file.name + index"
              class="flex items-center bg-primary/10 px-2 py-1.5 rounded-md leading-none"
              :class="{
                'text-black': citationFile.isValid,
                'text-red-600': !citationFile.isValid,
              }"
            >
              {{ citationFile.file.name }}
              <button
                class="hover:text-black text-black/60"
                @click="removeCitationFile(citationFile.file)"
              >
                <XIcon class="w-4 h-4 stroke-2" />
              </button>
            </div>
          </div>
          <div
            v-if="citationFiles.length > 0 && citationFilesError"
            class="text-red-600 text-xs"
          >
            {{ citationFilesError }}
          </div>
        </div>
        <button
          class="bg-primary text-white px-3 py-2 rounded-md !mt-0"
          @click="triggerFilePicker"
        >
          <DocumentTextIcon class="text-white w-5 h-5" />
        </button>
      </div>
    </template>
  </DropZoneFileUpload>
</template>
<script setup lang="ts">
import DropZoneFileUpload from '@app/components/Global/Inputs/DropZoneFileUpload.vue'
import DocumentTextIcon from '@app/components/Icons/DocumentTextIcon.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import { watchEffect } from 'vue'

defineProps<{
  isRequired?: boolean
}>()

const citationFiles = defineModel<{ isValid: boolean; file: File }[]>({
  default: [],
})

function removeCitationFile(file: File) {
  citationFiles.value = citationFiles.value.filter((f) => f.file !== file)
}
const citationFilesError = defineModel<string>('error')

function addCitationFiles(files: File[]) {
  files.forEach((file) => {
    if (
      !citationFiles.value.some(
        (cf) => cf.file.name === file.name && cf.file.size === file.size,
      )
    ) {
      const reader = new FileReader()
      reader.onload = (e) => {
        let isValid = false
        const content = e.target?.result
        if (typeof content === 'string' && validateRISFile(content)) {
          isValid = true
        }
        citationFiles.value.push({ file, isValid })
      }
      reader.readAsText(file)
    }
  })
}
watchEffect(() => {
  citationFilesError.value = citationFiles.value.every((cf) => cf.isValid)
    ? ''
    : 'some of the files are invalid'
}, {})

function validateRISFile(content: string): boolean {
  const normalizedContent = content.replace(/\r\n|\xa0/gm, '\n')
  const studyRegex = /(.*?)ER\s{2}-/gs
  const rawStudies = normalizedContent.match(studyRegex)?.map(String)

  if (!rawStudies) return false

  // Validate each record
  return rawStudies.every((record) => {
    // Check if the record starts with a valid type field (TY  -) and is not empty
    const startsWithValidType = /^TY\s+-\s/m.test(record)
    return startsWithValidType && record.trim() !== ''
  })
}
</script>
