<template>
  <FloatingMenu
    ref="floatingMenu"
    placement="bottom-start"
    :offset="[0, -10]"
    :arrow="false"
  >
    <template #activator="{ isShown }">
      <div
        v-tooltip="'Add external member'"
        :class="{ 'bg-[#F2F6F8]': isShown, 'hover:brightness-110': !isShown }"
        class="pb-4 pt-1 px-1 flex gap-1 items-center hover:cursor-pointer text-black"
        @click="openMenu"
      >
        <UserPlusIcon class="fill-primary w-5 h-5" />
        <p
          class="font-medium text-base leading-4 tracking-tight whitespace-nowrap"
        >
          External member
        </p>
        <ChevronDownIcon
          class="w-4 h-4 stroke-[3] transition-all"
          :class="{
            'rotate-180': isShown,
          }"
        />
      </div>
    </template>
    <template #content>
      <div
        class="w-[620px] p-2 absolute bg-[#F2F6F8] space-y-2 border border-t-0 border-[#D9E4EA] dark:bg-[#103047]"
      >
        <TextInput
          v-model="newExternalMemberName"
          class="w-full text-sm font-normal"
          label="Add external member"
          placeholder="Type external member name"
          type="text"
          @keydown.enter="addNewExternalMember()"
        />
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import { Id } from '@core/domain/types/id.type'
import useProjects from '@app/views/Projects/use-projects'

const props = defineProps<{
  projectId: Id
}>()
const floatingMenu = ref()
const newExternalMemberName = ref('')

async function openMenu() {
  floatingMenu?.value?.show()
}
const { addProjectExternalMember } = useProjects()

function addNewExternalMember() {
  addProjectExternalMember(props.projectId, newExternalMemberName.value)
  newExternalMemberName.value = ''
  floatingMenu.value?.hide()
}
</script>
