<template>
  <Modal dismissible rounded="rounded-xl">
    <template #activator="{ show }">
      <button
        class="bg-white dark:bg-[#275374] dark:border-white inline-flex items-center gap-1 py-1.5 border text-sm border-primary rounded-md px-2 font-normal hover:brightness-110"
        @click.stop="show"
      >
        <MagnifyingGlassPlusIcon class="w-4 h-4" />
        <span>Add review</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[500px] px-8 py-4">
        <div class="flex justify-end items-end">
          <button class="" @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="space-y-3">
          <div class="flex flex-col justify-center items-center gap-2">
            <p class="text-black text-2xl font-bold leading-6 tracking[-0.4px]">
              Create a review
            </p>
          </div>
          <div v-if="!isBillingDisabled" class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <InformationCircleIcon
                  class="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                  You are adding a new review to your plan. You will be billed
                  19.90€ per billing cycle.
                </p>
              </div>
            </div>
          </div>
          <div class="space-y-4">
            <TextInput
              ref="reviewNameInput"
              v-model="review.name"
              label="Review name"
              type="text"
              placeholder="review name"
              :rules="[(v) => !!v || 'please enter a review name']"
              @keyup.enter="createReview(hide)"
            />

            <div class="flex justify-between gap-6">
              <button
                class="w-full bg-white text-primary border border-primary rounded-lg text-base font-medium py-3 hover:brightness-125"
                @click="hide"
              >
                Cancel
              </button>
              <button
                class="w-full bg-primary text-white rounded-lg text-base py-3 font-medium hover:brightness-125"
                @click="createReview(hide)"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="px-2 py-2 dark:bg-[#4E6875] dark:text-white"
        @click.stop=""
      ></div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import useProjects from '@app/views/Projects/use-projects'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { ref, inject } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import MagnifyingGlassPlusIcon from '@app/components/Icons/MagnifyingGlassPlusIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { HttpException } from '@core/exceptions/http.exception'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'

const isBillingDisabled = inject('isBillingDisabled')
const reviewNameInput = ref<InstanceType<typeof TextInput>>()
const loading = useLoading()
const snackbar = useSnackbar()
const props = defineProps<{
  projectId: Id
}>()

const review = ref<Review>(new Review({ name: '' }))
const projects = useProjects()

async function createReview(cb: () => void) {
  loading.start()
  try {
    const isReviewValid = reviewNameInput.value?.validate()
    if (!isReviewValid) return
    await projects.createReview(props.projectId, review.value.name)
    hideAll()
    review.value = new Review({ name: '' })
    cb()
    snackbar.show(SnackbarState.SUCCESS, 'Review created successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
