<template>
  <FloatingMenu
    ref="floatingMenu"
    placement="top-start"
    :offset="[0, 0]"
    @hide="reset"
  >
    <template #activator>
      <slot name="activator" :show="show" />
    </template>

    <template #content>
      <div
        class="w-[350px] flex gap-2 items-start shadow-xl absolute bg-white space-y-2 border bored border-[#D9E4EA] dark:bg-[#103047]"
      >
        <GenericAutoComplete
          :items="remainingUsers"
          :item-options="{
            displayProperty: 'email',
            filterProperties: ['email', 'firstName', 'lastName'],
            valueProperty: 'id',
          }"
          :placeholder="`add member / assign as ${props.role.toLocaleLowerCase()}`"
          class="w-full"
          :shadow="false"
          @update:model-value="addAndAssignUser"
        >
          <template #item="{ firstName, lastName, email }">
            {{ email }}
            {{ firstName && lastName ? `(${firstName} ${lastName})` : '' }}
          </template>
        </GenericAutoComplete>
      </div>
    </template>
  </FloatingMenu>
</template>

<script setup lang="ts">
import useUsers from '@app/composables/use-users'

import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { Id } from '@core/domain/types/id.type'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { ReviewRole } from '@core/domain/types/review-role.type'

const props = defineProps<{
  project: Project
  reviewId: Id
  role: ReviewRole
}>()

const { users } = useUsers()
const floatingMenu = ref()
const isShown = ref(false)

async function show() {
  isShown.value = true

  floatingMenu?.value?.show()
}
const { addAuthorToReview, addReviewerToReview, addApproverToReview } =
  useProjects()

const projectMembers = computed(() => {
  return props.project.users
})

function reset() {
  isShown.value = false
}

function addAndAssignUser(userId?: unknown) {
  if (!userId || typeof userId !== 'string') return
  const user = users.value.find((u) => u.id === userId)
  if (user) {
    if (props.role === ReviewRole.AUTHOR)
      addAuthorToReview(props.reviewId, userId)
    else if (props.role === ReviewRole.APPROVER)
      addApproverToReview(props.reviewId, userId)
    else if (props.role === ReviewRole.REVIEWER)
      addReviewerToReview(props.reviewId, userId)
  }
  floatingMenu?.value?.hide()
}

const remainingUsers = computed(() =>
  users.value.filter(
    (user) =>
      projectMembers?.value?.some((member) => member.id === user.id) &&
      !isUserAlreadyAssignedToRole(user.id),
  ),
)

function isUserAlreadyAssignedToRole(userId: string) {
  const review = props.project.reviews?.find((r) => r.id === props.reviewId)
  if (!review) return false
  if (props.role === ReviewRole.AUTHOR) {
    return review.authors.some((a) => a.id === userId)
  } else if (props.role === ReviewRole.APPROVER) {
    return review.approvers.some((a) => a.id === userId)
  } else if (props.role === ReviewRole.REVIEWER) {
    return review.reviewers.some((a) => a.id === userId)
  }
}
</script>
