<template>
  <div :id="`project-${project.id}`" class="rounded-md">
    <div
      v-if="!shouldRender"
      class="flex justify-between gap-2 px-6 py-1 items-center w-full h-14 rounded-b-md bg-azureish-white dark:bg-[#344F62] hover:bg-opacity-80 rounded-md"
    >
      <div class="w-full gap-2 min-w-0 flex">
        <div class="w-full">
          <h1 class="text-md truncate">Loading</h1>
          <p class="text-gray-500">please wait</p>
        </div>
      </div>
    </div>
    <div v-else class="bg-white rounded-md">
      <ExpansionPanel v-model="isExpanded" class="rounded-md">
        <template #activator="{ toggle, isShown }">
          <div
            class="pl-2 flex justify-between items-center h-14 cursor-pointer transition-all duration-200 border"
            :class="[
              isShown
                ? 'bg-primary border-primary rounded-t-md'
                : 'bg-white hover:bg-opacity-80 text-black/90  border-slate-300  rounded-md',
            ]"
            @click="toggle"
          >
            <div class="flex gap-2 items-center">
              <button
                class="px-2 text-white hover:bg-transparent"
                @click.stop="toggleFavorite()"
              >
                <StarIcon
                  class="w-5 h-5 hover:stroke-yellow-500"
                  :class="[
                    project.isFavorite
                      ? 'fill-yellow-500 stroke-yellow-500'
                      : isShown
                        ? 'stroke-white fill-transparent'
                        : 'stroke-primary fill-transparent dark:stroke-white',
                  ]"
                />
              </button>
              <p
                class="font-bold text-lg"
                :class="[isShown ? 'text-white' : '']"
              >
                {{ project.name }}
              </p>
            </div>
            <div class="flex">
              <CreateProjectReviewFloatingMenu
                v-if="isAdmin"
                :project-id="project.id!"
              />
              <ProjectPlan :project="project" :is-shown="isShown" />

              <button class="p-2" @click.stop="toggle">
                <ChevronUpIcon v-if="isShown" class="w-6 h-6 text-white" />
                <ChevronDownIcon v-else class="w-6 h-6 hover:brightness-125" />
              </button>
            </div>
          </div>
        </template>
        <template #default>
          <div
            v-if="(project.reviews?.length ?? 0) > 0"
            class="divide-y divide-black/20 rounded-b-lg border-dashed border-2 border-t-0 border-slate-400"
          >
            <div
              v-for="review in project.reviews"
              :key="'review-' + review.id"
              class="px-2 flex justify-between items-center h-12 hover:bg-black/5"
              :class="{
                'bg-gray-50': review.isArchived,
              }"
            >
              <RouterLink
                :to="`/reviews/${review.id}`"
                class="py-1 px-2 flex h-full w-full items-center"
              >
                <label
                  class="hover:cursor-pointer text-base leading-5"
                  :for="review.name"
                >
                  {{ review.name }}
                </label>
              </RouterLink>
              <div class="flex items-center">
                <span
                  v-if="review.isArchived"
                  class="inline-flex items-center rounded-full bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
                >
                  Archived
                </span>
                <FloatingMenu v-if="isAdmin" :offset="[-10, 14]">
                  <template #activator="{ show }">
                    <ActionButton class="px-2 h-full" @click.stop="show()">
                      <EllipsisHorizontalIcon class="w-6 h-6" />
                    </ActionButton>
                  </template>
                  <template #content>
                    <div
                      class="flex flex-col text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                      @click.stop=""
                    >
                      <FloatingTextInput
                        :value="review.name"
                        @save="updateReviewName(project.id!, review.id, $event)"
                      >
                        <template #activator="{ show }">
                          <button
                            class="py-2 px-2 h-full flex items-center w-full hover:bg-black/5 dark:hover:bg-[#40596C]"
                            @click.stop="show()"
                          >
                            Edit
                          </button></template
                        >
                      </FloatingTextInput>
                      <FloatingMenu :offset="[0, 7]">
                        <template #activator="{ show }">
                          <button
                            class="py-2 px-2 h-full flex items-center w-full hover:bg-black/5 dark:hover:bg-[#40596C]"
                            @click="show"
                          >
                            Delete
                          </button>
                        </template>
                        <template #content="{ hide }">
                          <div
                            class="p-4 w-96 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                          >
                            <div
                              v-if="!isBillingDisabled"
                              class="rounded-md bg-blue-50 p-4"
                            >
                              <div class="flex">
                                <div class="flex-shrink-0">
                                  <InformationCircleIcon
                                    class="h-5 w-5 text-blue-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div
                                  class="ml-3 flex-1 md:flex md:justify-between"
                                >
                                  <p class="text-sm text-blue-700">
                                    You are deleting a review from your plan.<br />
                                    <span class="font-bold">WARNING</span>:
                                    deleted reviews cannot be restored or
                                    recovered.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div v-else class="rounded-md bg-blue-50 p-4">
                              <div class="flex">
                                <p class="text-sm text-blue-700">
                                  You are deleting a review from your plan.<br />
                                  <span class="font-bold">WARNING</span>:
                                  deleted reviews cannot be restored or
                                  recovered.
                                </p>
                              </div>
                            </div>
                            <div class="mt-2">
                              <button
                                class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                                @click="hide"
                              >
                                cancel
                              </button>
                              <button
                                class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                                @click="
                                  deleteSelectedReviews(project.id!, [
                                    review.id,
                                  ])
                                "
                              >
                                delete
                              </button>
                            </div>
                          </div>
                        </template>
                      </FloatingMenu>
                      <FloatingMenu :offset="[0, 7]">
                        <template #activator="{ show }">
                          <button
                            class="py-2 px-2 h-full flex items-center w-full hover:bg-black/5 dark:hover:bg-[#40596C]"
                            @click="show"
                          >
                            {{ review.isArchived ? 'Restore' : 'Archive' }}
                          </button>
                        </template>
                        <template #content="{ hide }">
                          <div
                            class="p-4 w-96 text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
                          >
                            <div
                              v-if="!isBillingDisabled"
                              class="rounded-md bg-blue-50 p-4"
                            >
                              <div class="flex">
                                <div class="flex-shrink-0">
                                  <InformationCircleIcon
                                    class="h-5 w-5 text-blue-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div
                                  class="ml-3 flex-1 md:flex md:justify-between"
                                >
                                  <p
                                    v-if="!review.isArchived"
                                    class="text-sm text-blue-700"
                                  >
                                    You are archiving a review. You will be
                                    billed 3€ instead of 19.90€ per billing
                                    cycle.<br />
                                    <span class="font-bold">WARNING</span>:
                                    Restoring an archived review at any time
                                    will add a new review to your plan.
                                  </p>
                                  <p v-else class="text-sm text-blue-700">
                                    You are restoring an archived review. You
                                    will be billed 19.90€ per billing cycle.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div v-else class="rounded-md bg-blue-50 p-4">
                              <div class="flex">
                                <div
                                  class="ml-3 flex-1 md:flex md:justify-between"
                                >
                                  <p
                                    v-if="!review.isArchived"
                                    class="text-sm text-blue-700"
                                  >
                                    Are you sure you want to archive this
                                    review? <br />
                                  </p>
                                  <p v-else class="text-sm text-blue-700">
                                    Are you sure you want to restore this
                                    review?
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="mt-2 flex justify-between">
                              <button
                                class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                                @click="hide"
                              >
                                cancel
                              </button>
                              <button
                                class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                                @click="updateReviewArchiveState(review)"
                              >
                                {{ review.isArchived ? 'Restore' : 'Archive' }}
                              </button>
                            </div>
                          </div>
                        </template>
                      </FloatingMenu>
                    </div>
                  </template>
                </FloatingMenu>
              </div>
            </div>
          </div>
          <div
            v-else
            class="p-4 border-dashed border-2 border-primary/30 text-center space-y-4"
          >
            <p>
              There are no review in this project yet, start by creating one
            </p>
            <div>
              <CreateProjectReviewFloatingMenu
                v-if="isAdmin"
                :project-id="project.id!"
              />
            </div>
          </div>
        </template>
      </ExpansionPanel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import StarIcon from '@app/components/Icons/StarIcon.vue'
import CreateProjectReviewFloatingMenu from '@app/views/Projects/CreateProjectReviewFloatingMenu.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { SnackbarState } from '@app/types'
import { Project } from '@core/domain/models/project.model'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, onMounted, ref, nextTick, inject } from 'vue'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import useLoading from '@app/composables/use-loading'
import useProjects from '@app/views/Projects/use-projects'
import { User } from '@core/domain/models/user.model'
import ActionButton from '@app/components/Global/ActionButton.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import ChevronUpIcon from '@app/components/Icons/ChevronUpIcon.vue'
import EllipsisHorizontalIcon from '@app/components/Icons/EllipsisHorizontalIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { Role } from '@core/domain/types/role.type'
import ProjectPlan from './ProjectPlan/ProjectPlan.vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import { useStorage } from '@vueuse/core'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import { InformationCircleIcon } from '@heroicons/vue/24/solid'

const isBillingDisabled = inject('isBillingDisabled')

const props = defineProps<{
  project: Project
  users: User[]
}>()

const isExpanded = useStorage(`isShown-${props.project.id}`, true)

const snackbar = useSnackbar()
const loading = useLoading()
const projects = useProjects()
const { user } = injectStrict(AuthKey)

const selectedReviewsIds = ref<Id[]>([])

function toggleFavorite() {
  projects.toggleFavorite(props.project.id!)
  if (props.project.isFavorite) {
    snackbar.show(SnackbarState.SUCCESS, 'set project as favorite')
  } else {
    snackbar.show(SnackbarState.SUCCESS, 'set project as unfavorite')
  }
}

async function updateReviewName(
  projectId: Id,
  reviewId: Id,
  name: string,
): Promise<void> {
  try {
    loading.start()
    await projects.updateReviewName(projectId, reviewId, name)
    snackbar.show(SnackbarState.SUCCESS, 'review name updated')
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

async function deleteSelectedReviews(projectId: Id, selectedReviewsIds: Id[]) {
  try {
    loading.start()
    if (selectedReviewsIds.length === 0)
      snackbar.show(SnackbarState.ERROR, 'there is no reviews selected')
    else {
      await projects.deleteSelectedReviews(projectId, selectedReviewsIds)
      resetSelectedReviewsIds()
      snackbar.show(SnackbarState.SUCCESS, 'reviews deleted successfully')
      hideAll()
    }
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function archiveReview(reviewId: Id) {
  try {
    loading.start()
    await projects.archiveReview(reviewId)
    snackbar.show(SnackbarState.SUCCESS, 'review archived successfully')
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function unarchiveReview(reviewId: Id) {
  try {
    loading.start()
    await projects.unarchiveReview(reviewId)
    snackbar.show(SnackbarState.SUCCESS, 'review restored successfully')
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function updateReviewArchiveState(review: Review) {
  if (review.isArchived) {
    await unarchiveReview(review.id)
  } else {
    await archiveReview(review.id)
  }
}
function resetSelectedReviewsIds() {
  selectedReviewsIds.value = []
}

const shouldRender = ref(false)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
