<template>
  <div class="w-full">
    <div class="relative">
      <div
        class="flex flex-col hover:brightness-95 dark:bg-[#4E6875] bg-white border rounded-lg px-5 py-4 gap-1"
        :class="borderClass"
        @click="focusInput"
      >
        <label
          v-if="label"
          :class="labelClass"
          class="text-sm font-bold leading-none flex gap-1"
        >
          <span>{{ label }}</span>
          <span v-if="successMessage" class="text-green-600">{{
            successMessage
          }}</span>
        </label>

        <input
          ref="input"
          v-model="internalValue"
          :disabled="isLoading"
          class="text-black/70 transition-colors appearance-none w-full focus:outline-none dark:text-white placeholder-black/40 placeholder-opacity-70 dark:placeholder-gray-400"
          :class="[
            isDifferent ? 'italic' : '',
            {
              'cursor-wait': isLoading,
            },
            ,
            { 'text-green-600': successMessage },
            { 'text-red-600': error },
          ]"
          :placeholder="placeholder ? placeholder : label"
          @blur="emitUpdate"
          @keydown.enter="emitUpdate"
          @input="onInput"
        />
      </div>

      <div
        v-if="isLoading"
        class="absolute w-full bottom-0 left-0 overflow-hidden bg-primary/40 h-1"
      >
        <div
          class="progress-bar bg-primary absolute bottom-0 top-0 w-1/2"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createDebounce } from '@app/utils/debounce'
import { computed, ref, watchEffect } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string
    placeholder?: string
    value: string
    trigger?: 'manual' | 'automatic'
  }>(),
  {
    label: '',
    placeholder: '',
    trigger: 'manual',
  },
)

const emit = defineEmits<(e: 'update', v: string) => void>()

const debounce = createDebounce()
const input = ref()
const internalValue = ref(props.value)
const isDifferent = computed(() => internalValue.value !== props.value)

watchEffect(() => {
  internalValue.value = props.value
})

const isLoading = ref(false)
const successMessage = ref('')
const error = ''

const borderClass = computed(() => {
  if (successMessage.value) {
    return 'border-green-600'
  } else if (error) {
    return 'border-red-600'
  } else {
    return 'border-primary/40'
  }
})
const labelClass = computed(() => {
  if (successMessage.value.length > 0) {
    return 'text-green-600'
  } else if (error) {
    return 'text-red-600'
  } else {
    return 'text-black'
  }
})

function onInput() {
  successMessage.value = ''
  if (props.trigger === 'automatic') debounce(() => emitUpdate())
}
function displaySuccess() {
  successMessage.value = 'successfully updated'
  setTimeout(() => (successMessage.value = ''), 3000)
}

function startLoading() {
  isLoading.value = true
}
function stopLoading() {
  isLoading.value = false
}

function emitUpdate() {
  if (internalValue.value !== props.value) {
    emit('update', internalValue.value)
  }
}

const focusInput = () => {
  if (input.value) {
    input.value.focus()
  }
}

defineExpose({
  startLoading,
  stopLoading,
  displaySuccess,
})
</script>

<style>
/* Existing styles remain the same */
.progress-bar {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
