<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 4C8.44183 4 8.8 4.35817 8.8 4.8V7.2L11.2 7.2C11.6418 7.2 12 7.55817 12 8C12 8.44183 11.6418 8.8 11.2 8.8H8.8V11.2C8.8 11.6418 8.44183 12 8 12C7.55817 12 7.2 11.6418 7.2 11.2V8.8H4.8C4.35817 8.8 4 8.44183 4 8C4 7.55817 4.35817 7.2 4.8 7.2L7.2 7.2V4.8C7.2 4.35817 7.55817 4 8 4Z"
      fill="currentColor"
    />
  </svg>
</template>
