<template>
  <ImportSourceCard
    :import-source="importSource"
    :tooltip="{
      text: 'import studies from custom source',
      url: '/docs/reviews.html#custom-database-and-other-sources',
    }"
    @import-source-removed="emit('importSourceRemoved')"
  >
    <template
      v-if="review.entity.value.plan?.lockState !== ReviewLockState.LOCKED"
      #bottom
    >
      <ExpansionPanel :model-value="false">
        <template #activator="{ toggle, isShown }">
          <button
            class="text-primary mb-2 flex items-center w-full gap-4"
            @click="toggle"
          >
            <div>Import search</div>
            <div class="flex-1 h-px bg-black/5"></div>
            <ChevronDownIcon
              class="w-4 h-4 stroke-2 transform transition-transform"
              :class="{
                'rotate-180': isShown,
              }"
            />
          </button>
        </template>
        <template #default>
          <div class="space-y-2 pb-4">
            <div>
              <DatePicker
                v-model="date"
                label="Search date"
                placeholder="Search date"
                :error="date ? dateValidationError : ''"
                :is-required="true"
              />
            </div>
            <div>
              <TextInput
                v-model="query"
                label="Query"
                placeholder="Search query"
                class="w-full"
                :is-required="importSource.type === ImportSourceType.DATABASE"
              />
            </div>
            <div>
              <TextInput
                v-model="filters"
                label="Filters"
                placeholder="Filters"
                class="w-full"
                multiline
                can-resize
              />
            </div>

            <div class="flex gap-1">
              <button @click.stop="toggleEmptySearchCheckbox()">
                <Checkbox
                  v-model="emptySearchCheckbox"
                  class="w-5 h-5"
                ></Checkbox>
              </button>
              <p class="text-primary">Search returned no records.</p>
            </div>

            <div v-if="emptySearchCheckbox !== CheckboxState.checked">
              <RisDropZone
                ref="risDropZoneComponent"
                v-model="citationFiles"
                v-model:error="citationFilesError"
              />
            </div>
          </div>

          <button
            :disabled="
              !!citationFilesError ||
              !!dateValidationError ||
              (importSource.type === ImportSourceType.DATABASE && !query)
            "
            :class="{
              '!bg-black/5 !text-black/40':
                !!citationFilesError ||
                !!dateValidationError ||
                (importSource.type === ImportSourceType.DATABASE && !query),
            }"
            class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
            @click="
              importRisStudies({
                date,
                files: citationFiles,
                sourceId: importSource.id,
                query,
                filters,
              })
            "
          >
            Import
          </button>
        </template>
      </ExpansionPanel>
      <div>
        <a
          v-if="isExtensionInstalled"
          :href="importSource.url"
          target="_blank"
          rel="noopener"
          class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
        >
          Go to {{ importSource.name }}
        </a>
        <div
          v-else
          class="rounded-md border-primary/40 text-black/80 text-center border px-2 py-2 text-sm"
        >
          Install the
          <a
            href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
            target="_blank"
            rel="noopener"
            class="text-primary underline"
          >
            chrome extension
          </a>
          to import from {{ importSource.name }}
        </div>
      </div>
    </template>
  </ImportSourceCard>
</template>
<script setup lang="ts">
import { ImportSource } from '@core/domain/models/import-source.model'
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import { computed, ref } from 'vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import RisDropZone from '../Components/RisDropZone.vue'
import { format } from 'date-fns'
import DatePicker from '@app/components/Global/Inputs/DatePicker.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import useWebExtension from '@app/composables/useWebExtension'
const { isExtensionInstalled } = useWebExtension()
const emit = defineEmits<(e: 'importSourceRemoved') => void>()

const props = defineProps<{
  importSource: ImportSource
  readonly?: boolean
}>()
const date = ref(format(new Date(), 'yyyy-MM-dd'))
const query = ref('')
const filters = ref('')

const dateValidationError = computed<string>(() => {
  return date.value ? '' : 'date is required'
})
const citationFiles = ref<{ isValid: boolean; file: File }[]>([])
const citationFilesError = ref('')
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const emptySearchCheckbox = ref<CheckboxState>(CheckboxState.uncheked)

function toggleEmptySearchCheckbox() {
  if (emptySearchCheckbox.value === CheckboxState.checked)
    emptySearchCheckbox.value = CheckboxState.uncheked
  else emptySearchCheckbox.value = CheckboxState.checked
}

async function importRisStudies({
  files,
  date,
  sourceId,
  query,
  filters,
}: {
  files: { isValid: boolean; file: File }[]
  date: string
  sourceId: string
  query?: string
  filters?: string
}) {
  if (
    !date ||
    files.some((cf) => !cf.isValid) ||
    (props.importSource.type === ImportSourceType.DATABASE && !query)
  ) {
    throw new Error('Missing or incorrect data')
  }
  loading.start()
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === sourceId,
    )
    if (!dataSource) {
      throw new Error('import source does not exist')
    }
    await review.importRisSearch({
      citationFiles: files.map((cf) => cf.file),
      date,
      query,
      filters,
      importSourceId: sourceId,
    })
    reset()
    snackbar.show(SnackbarState.SUCCESS, 'Studies imported successfully')
  } catch (e) {
    snackbar.show(SnackbarState.ERROR, 'Import failed, please try again')
    throw e
  } finally {
    loading.stop()
  }
}

function reset() {
  citationFiles.value = []
  date.value = format(new Date(), 'yyyy-MM-dd')
  query.value = ''
  filters.value = ''
  emptySearchCheckbox.value = CheckboxState.uncheked
}
</script>
