<template>
  <Modal v-if="isAdmin" dismissible>
    <template #activator="{ show }">
      <button
        class="ml-2 px-2 py-2"
        :class="[isShown ? 'text-white/80 hover:text-white' : '']"
        @click.stop="show"
      >
        <SettingsIcon class="w-5 h-5" />
      </button>
    </template>
    <template #content>
      <div class="w-[calc(100vw-120px)] h-[calc(100vh-120px)] rounded-lg">
        <div class="h-full flex flex-col">
          <Tabs
            :steps="[
              { number: 1, title: 'Members' },
              { number: 2, title: 'General' },
            ]"
          >
            <template #step1>
              <ProjectMembersAssignment :project="project" />
            </template>
            <template #step2>
              <ProjectSettings :project="project" />
            </template>
          </Tabs>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs.vue'
import { Project } from '@core/domain/models/project.model'
import ProjectMembersAssignment from './ProjectMembersAssignment/ProjectMembersAssignment.vue'
import ProjectSettings from './ProjectSettings/ProjectSettings.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import SettingsIcon from '@app/components/Icons/SettingsIcon.vue'
import { computed } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'

defineProps<{
  project: Project
  isShown?: boolean
}>()

const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
