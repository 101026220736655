<template>
  <ImportSourceCard
    :import-source="handSearch"
    :tooltip="{
      text: 'perform a hand search and manually import studies.',
      url: '/docs/reviews.html#hand-search',
    }"
    @import-source-removed="emit('importSourceRemoved')"
  >
    <template
      v-if="review.entity.value.plan?.lockState !== ReviewLockState.LOCKED"
      #bottom
    >
      <ExpansionPanel :model-value="false">
        <template #activator="{ toggle, isShown }">
          <button
            class="text-primary mb-2 flex items-center w-full gap-4"
            @click="toggle"
          >
            <div>Import search</div>
            <div class="flex-1 h-px bg-black/5"></div>
            <ChevronDownIcon
              class="w-4 h-4 stroke-2 transform transition-transform"
              :class="{
                'rotate-180': isShown,
              }"
            />
          </button>
        </template>
        <template #default>
          <div class="space-y-2 pb-4">
            <RisDropZone
              ref="risDropZoneComponent"
              v-model="citationFiles"
              v-model:error="citationFilesError"
              is-required
            />
          </div>

          <button
            :disabled="!!citationFilesError || citationFiles.length === 0"
            :class="{
              '!bg-black/5 !text-black/40':
                !!citationFilesError || citationFiles.length === 0,
            }"
            class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
            @click="
              importRisStudies({
                files: citationFiles,
                sourceId: BuiltInImportSourceId.HAND_SEARCH,
              })
            "
          >
            Import
          </button>
        </template>
      </ExpansionPanel>
    </template>
  </ImportSourceCard>
</template>
<script setup lang="ts">
import ImportSourceCard from '../Components/ImportSourceCard.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { SnackbarState } from '@app/types'
import { ref } from 'vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import RisDropZone from '../Components/RisDropZone.vue'
import { format } from 'date-fns'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'

const builtInImportSources = useBuiltInImportSources()
const emit = defineEmits<(e: 'importSourceRemoved') => void>()

const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!

const citationFiles = ref<{ isValid: boolean; file: File }[]>([])
const citationFilesError = ref('')
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

async function importRisStudies({
  files,
  sourceId,
}: {
  files: { isValid: boolean; file: File }[]
  sourceId: string
}) {
  if (files.length === 0 || files.some((cf) => !cf.isValid)) {
    throw new Error('Missing or incorrect data')
  }
  loading.start()
  try {
    const dataSource = review.entity.value.plan?.importPlan.importSources?.some(
      (x) => x.id === sourceId,
    )
    if (!dataSource) {
      throw new Error('import source does not exist')
    }
    await review.importRisSearch({
      citationFiles: files.map((cf) => cf.file),
      date: format(new Date(), 'yyyy-MM-dd'),
      importSourceId: sourceId,
    })
    reset()
    snackbar.show(SnackbarState.SUCCESS, 'Studies imported successfully')
  } catch (e) {
    snackbar.show(SnackbarState.ERROR, 'Import failed, please try again')
    throw e
  } finally {
    loading.stop()
  }
}

function reset() {
  citationFiles.value = []
}
</script>
