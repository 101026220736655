<template>
  <GenericAutoComplete
    v-model="selectedCslStyle"
    :items="cslStyleListItems"
    :item-options="{
      displayProperty: 'text',
      filterProperties: ['text'],
      valueProperty: 'value',
    }"
    placeholder="Select Citation style"
    @update:model-value="updateCslStyle"
  >
    <template #label>
      <label class="text-sm text-primary font-medium flex gap-2 items-center">
        Citation style
        <FloatingMenu>
          <template #activator="{ show }">
            <button class="block" @click.stop="show">
              <InformationCircleIcon class="w-5 h-5 text-black/80" />
            </button>
          </template>
          <template #content>
            <div class="w-64 p-2">
              <p>
                Select your preferred citation style from the list, which will
                apply to in-text citations and the bibliography in project
                documents.
              </p>
              <div>
                <a
                  class="text-primary underline"
                  target="_blank"
                  href="/docs/projects.html#citation-style"
                  @click.stop=""
                >
                  Learn more
                </a>
              </div>
            </div>
          </template>
        </FloatingMenu>
      </label>
    </template>
    <template #item="{ text }">
      {{ text }}
    </template>
  </GenericAutoComplete>
</template>

<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useCslStyles from '@app/composables/use-cslStyles'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { HttpException } from '@core/exceptions/http.exception'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { computed, ref } from 'vue'

const props = defineProps<{
  project: Project
}>()

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()

const cslStyles = useCslStyles()
const selectedCslStyle = ref(props.project.cslStyle)

const cslStyleListItems = computed(() =>
  cslStyles.list.value
    .map((s) => {
      const text = s.charAt(0).toUpperCase() + s.replaceAll('-', ' ').slice(1)
      return { text: text.replaceAll('-', ' '), value: s }
    })
    .sort((a, b) => a.text.localeCompare(b.text)),
)

async function updateCslStyle(style?: unknown) {
  if (!style || typeof style !== 'string') return
  try {
    loading.start()
    selectedCslStyle.value = style
    await projects.updateCslStyle(props.project.id!, style)
    snackbar.show(SnackbarState.SUCCESS, 'Citation style updated')
  } catch (e) {
    const error = e as HttpException
    selectedCslStyle.value = props.project.cslStyle
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
