<template>
  <teleport to="#toolbar">
    <div class="w-full px-10 py-2">
      <div class="inline-flex text-xl font-medium leading-5 py-7 pr-6">
        Users
      </div>
      <CreateUsersFloatingMenu />
    </div>
  </teleport>
  <div class="max-w-5xl container pl-10 py-8">
    <Switch v-model="shouldShowInactive" class="mb-4">
      Show inactive users
    </Switch>
    <UsersList :show-inactive="shouldShowInactive" />
  </div>
</template>
<script lang="ts" setup>
import CreateUsersFloatingMenu from '@app/views/Users/CreateUsersFloatingMenu.vue'
import UsersList from './Users/UsersList.vue'
import { ref } from 'vue'
import Switch from '@app/components/Global/Switch.vue'
const shouldShowInactive = ref(false)
</script>
