import { io } from 'socket.io-client'
import { InjectionKey } from 'vue'

export const EventHandlerKey: InjectionKey<EventHandler> =
  Symbol('EventHandler')

export class EventHandler {
  private socket = io('/reviews', {
    path: '/events',
  })
  private handlers: Map<number, (data: { reviewId: number }) => void> =
    new Map()
  onReviewSearchCreated(reviewId: number, fn: () => void | Promise<void>) {
    const handler = (data: { reviewId: number }) => {
      if (data.reviewId === reviewId) fn()
    }

    this.socket.on('reviewUpdated', handler)
    this.handlers.set(reviewId, handler)
  }

  unregisterReviewSearchHandler(reviewId: number) {
    const handler = this.handlers.get(reviewId)
    if (handler) {
      this.socket.off('reviewUpdated', handler)
      this.handlers.delete(reviewId)
    }
  }
}
