<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M0.799805 8.41172C0.799805 7.96299 1.17488 7.59922 1.63756 7.59922C2.10023 7.59922 2.47531 7.96299 2.47531 8.41172V13.2867C2.47531 13.7355 2.10023 14.0992 1.63756 14.0992C1.17488 14.0992 0.799805 13.7355 0.799805 13.2867V8.41172Z"
      fill="white"
    />
    <path
      d="M7.50182 4.99922V4.15432C7.50182 3.9803 7.59547 3.81236 7.76651 3.75977C7.89444 3.72044 8.03067 3.69922 8.17202 3.69922C8.9123 3.69922 9.51242 4.28125 9.51242 4.99922C9.51242 5.64599 9.39067 6.26519 9.16819 6.83642C9.03139 7.18766 9.27947 7.59922 9.6666 7.59922H11.3555C12.1883 7.59922 12.8709 8.25544 12.7939 9.0597C12.6645 10.4115 12.3572 11.7133 11.895 12.9428C11.6259 13.6588 10.9013 14.0992 10.1155 14.0992H7.97646C7.66432 14.0992 7.35647 14.0287 7.07729 13.8934L5.24554 13.0051C4.96636 12.8697 4.65851 12.7992 4.34637 12.7992H3.48061V8.24922H4.12615C4.58485 8.24922 4.96916 7.93558 5.20665 7.55497C5.53402 7.03034 6.04723 6.62697 6.65777 6.43071C6.94783 6.33747 7.22987 6.17959 7.33593 5.90165C7.44321 5.62053 7.50182 5.31651 7.50182 4.99922Z"
      fill="white"
    />
    <path
      opacity="0.9"
      d="M13.6002 1.70078C13.6002 1.47987 13.4211 1.30078 13.2002 1.30078C12.9793 1.30078 12.8002 1.47987 12.8002 1.70078V2.90078H11.6002C11.3793 2.90078 11.2002 3.07987 11.2002 3.30078C11.2002 3.5217 11.3793 3.70078 11.6002 3.70078H12.8002V4.90078C12.8002 5.1217 12.9793 5.30078 13.2002 5.30078C13.4211 5.30078 13.6002 5.1217 13.6002 4.90078V3.70078H14.8002C15.0211 3.70078 15.2002 3.52169 15.2002 3.30078C15.2002 3.07987 15.0211 2.90078 14.8002 2.90078H13.6002V1.70078Z"
      fill="white"
    />
  </svg>
</template>
