<template>
  <div>
    <Modal dismissible>
      <template #activator="{ show: showComplaintModal }">
        <button
          v-tooltip="complaintReportingHintMessage"
          class="flex items-center p-2 gap-1 hover:bg-black/20"
          :class="{
            ' text-black/20 ': !canReportComplaint,
          }"
          @click.stop="canReportComplaint && showComplaintModal()"
        >
          <PaperAirplaneIcon class="w-5 h-5" /> submit complaint
        </button>
      </template>
      <template #content="{ hide: hideComplaintModal }">
        <div class="flex flex-col gap-2 px-2 py-1">
          <div class="flex gap-2">
            <div class="w-[800px]">
              <div class="flex">
                <div class="py-2">
                  <label class="block text-sm font-bold"> Message </label>
                  <InlineTextInput
                    v-model="message"
                    placeholder="Please Write Your Message"
                    :multiline="true"
                    class="w-full"
                  />
                </div>
              </div>
              <div class="flex justify-end gap-2 w-full">
                <FloatingMenu placement="top" :offset="[0, 10]">
                  <template #activator="{ show: showConfirmationFloatingMenu }">
                    <button
                      :class="
                        isValid
                          ? 'hover:bg-slate-700  bg-slate-600'
                          : 'bg-slate-400 cursor-not-allowed'
                      "
                      class="text-white p-0.5"
                      @click="showConfirmationFloatingMenu"
                    >
                      <PaperAirplaneIcon class="w-5 h-5" />
                    </button>
                  </template>
                  <template #content="{ hide: hideConfirmationModal }">
                    <div class="px-2 py-1">
                      <p class="mb-1">are you sure?</p>
                      <div class="flex gap-1">
                        <button
                          class="bg-neutral-500 text-white py-0.5 px-1"
                          @click="hideConfirmationModal"
                        >
                          no
                        </button>
                        <button
                          class="bg-green-600 text-white py-0.5 px-1"
                          @click="
                            ;[
                              hideConfirmationModal(),
                              submitComplaint(hideComplaintModal),
                            ]
                          "
                        >
                          yes
                        </button>
                      </div>
                    </div>
                  </template>
                </FloatingMenu>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

import { format } from 'date-fns'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import useSnackbar from '@app/composables/use-snackbar'
import { useSettings } from '@app/composables/use-settings'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { SnackbarState } from '@app/types'
import Modal from '@app/components/Global/Modal/Modal.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import PaperAirplaneIcon from '@app/components/Icons/PaperAirplaneIcon.vue'
import { AuthKey } from '@app/injectionKeys'

type Party = {
  email: string
  name: string
}
const props = defineProps<{
  study: ReviewItem
}>()

const snackbar = useSnackbar()
const settings = useSettings()
const { user } = injectStrict(AuthKey)
const review = injectStrict(ReviewKey)
const loading = useLoading()

let reporterName = ''
if (user.value?.firstName) {
  reporterName += user.value?.firstName + ' '
}
if (user.value?.firstName) {
  reporterName += user.value?.lastName
}

let reporterEmail = user.value?.email ?? ''
const reporter = ref<Party>({
  email: reporterEmail,
  name: reporterName,
})

let subject = ref<string>('Complaint from literature - %STUDYTITLE%')

let text = ` Subject of the email: Complaint from literature - %STUDYTITLE%

To %RECIPIENTEMAIL%

I am currently working on a literature review for PRODUCT NAME. I came across a study that reports on:
[x] Device deficiencies
[x] Adverse events / clinical issues potentially associated to the use of the device
[x] Off label use or misuse of the device
[x] Issues associated to the information material of the device
The PDF of the study is attached to this email. This study was retrieved from %SOURCE NAME% on %DATE%. A data extraction was performed today, %TODAYS DATE%.

Please, do not reply to this email. For further information, please contact: %REPORTERFULLNAME% at %REPORTERMAIL%

Please. note that this complaint was identified through existing published literature and I will not be able to provide any further information outside of what is available within the attached PDF.

Regards,
%REPORTERFULLNAME%`

const complaintReportingHintMessage = computed(() => {
  let hintMessage = 'Submit a complaint'
  if (!review.entity.value?.project?.contactForComplaintEmail) {
    hintMessage = 'Complaint contact email is missing'
  }
  if (!user.value?.firstName || !user.value?.lastName) {
    hintMessage = 'User name missing or incomplete'
  }
  return hintMessage
})

const canReportComplaint = computed(() => {
  return (
    !!review.entity.value?.project?.contactForComplaintEmail &&
    !!user.value?.firstName &&
    !!user.value?.lastName
  )
})

function prepareMessage() {
  text = text.replaceAll('%STUDYTITLE%', props.study.metadata?.title ?? '')
  subject.value = subject.value.replaceAll(
    '%STUDYTITLE%',
    props.study.metadata?.title ?? '',
  )
  text = text.replaceAll('%REPORTERFULLNAME%', reporter.value.name)
  text = text.replaceAll('%REPORTERMAIL%', reporter.value.email)
  text = text.replaceAll(
    '%RECIPIENTEMAIL%',
    review.entity.value?.project?.contactForComplaintEmail ?? '',
  )

  text = text.replaceAll('%SOURCE NAME%', props.study.search?.source.name ?? '')
  text = text.replaceAll(
    '%TODAYS DATE%',
    format(new Date(), settings.dateFormat.value),
  )
  if (
    ![ImportSourceType.HAND_SEARCH, ImportSourceType.CITATION_SEARCH].includes(
      props.study.search!.source.type!,
    )
  )
    text = text.replaceAll(
      '%DATE%',
      format(
        new Date(props.study.search?.searchDate ?? ''),
        settings.dateFormat.value,
      ),
    )
  else text = text.replaceAll('on %DATE%', '')
  return text
}

const message = ref<string>(prepareMessage())

const isValid = computed(() => {
  return message.value
})

async function submitComplaint(callback: () => void) {
  if (!isValid.value) return
  try {
    loading.start()
    await review.submitComplaint(props.study.id, {
      fromAddress: reporter.value.email,
      fromName: reporter.value.name,
      to: review.entity.value?.project?.contactForComplaintEmail ?? '',
      subject: subject.value,
      body: message.value,
    })
    snackbar.show(SnackbarState.SUCCESS, 'Report sent successfully')

    callback()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
