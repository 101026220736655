<template>
  <div class="flex items-center gap-2">
    <a
      v-if="!isExtensionInstalled"
      id="install-extension-link"
      href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
      target="_blank"
      rel="noopener"
    >
      <img
        class="h-12"
        src="https://storage.googleapis.com/web-dev-uploads/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/UV4C4ybeBTsZt43U4xis.png"
        alt="Install Evidence Extension"
      />
    </a>
    <FloatingMenu
      ref="floatingMenu"
      placement="bottom-start"
      :arrow="true"
      :offset="[0, 10]"
    >
      <template #activator>
        <button
          class="font-bold border p-5 text-white rounded-full bg-primary w-5 h-5 text-sm uppercase flex items-center justify-center dark:bg-[#103047] dark:text-white"
          @click="openMenu"
        >
          {{ user?.email?.charAt(0) ?? '' }}
        </button>
      </template>
      <template #content>
        <div
          class="w-[128px] text-silent-night dark:bg-[#103047] dark:text-white dark:border-white/10 border"
        >
          <button
            class="py-2 px-4 uppercase tracking-wide hover:bg-black/5 dark:hover:bg-[#344F62] w-full flex"
            @click="logout"
          >
            logout
          </button>
          <Modal dismissible rounded="rounded-2xl">
            <template #activator="{ show }">
              <button
                class="py-2 px-4 uppercase tracking-wide hover:bg-black/5 dark:hover:bg-[#344F62] w-full flex"
                @click="show"
              >
                profile
              </button>
            </template>
            <template #content="{ hide }">
              <div class="w-[548px] h-[460px] mt-6">
                <div class="flex justify-end items-end px-6">
                  <button class="" @click="hide">
                    <XIcon class="w-6 h-6" />
                  </button>
                </div>
                <div class="space-y-6">
                  <div class="flex flex-col justify-center items-center gap-2">
                    <p
                      class="text-black text-2xl font-bold leading-6 tracking[-0.4px]"
                    >
                      Profile
                    </p>
                  </div>
                  <div class="px-16 space-y-3">
                    <TextInput
                      placeholder="Email"
                      :model-value="user?.email"
                      label="Email"
                      :disabled="true"
                      class="px-1 py-2"
                    />
                    <TextInput
                      v-model="userFullName.firstName"
                      placeholder="First name"
                      label="First name"
                      class="px-1 py-2"
                    />
                    <TextInput
                      v-model="userFullName.lastName"
                      placeholder="Last name"
                      label="Last name"
                      class="px-1 py-2"
                    />
                    <div class="flex justify-between">
                      <button
                        class="w-[191.55px] bg-white text-black border border-primary rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
                        @click="hide"
                      >
                        Cancel
                      </button>
                      <button
                        class="w-[191.55px] bg-primary text-white rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
                        @click="updateCurrentUserFullName(userFullName, hide)"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
      </template>
    </FloatingMenu>
  </div>
</template>

<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref } from 'vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { UsersService } from '@core/application/users.service'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useUsers from '@app/composables/use-users'
import useLoading from '@app/composables/use-loading'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey, UsersServiceKey } from '@app/injectionKeys'
import useWebExtension from '@app/composables/useWebExtension'

const snackbar = useSnackbar()
const { user, setUser, logout: authLogout } = injectStrict(AuthKey)

const users = useUsers()

const { isExtensionInstalled, logoutExtension } = useWebExtension()
const loading = useLoading()
const usersService: UsersService = injectStrict(UsersServiceKey)

const floatingMenu = ref()

const userFullName = ref({
  firstName: user.value?.firstName ?? '',
  lastName: user.value?.lastName ?? '',
})

async function updateCurrentUserFullName(
  fullName: {
    firstName: string
    lastName: string
  },
  callback: () => void,
) {
  try {
    loading.start()
    await users.updateCurrentUserFullName(fullName)
    snackbar.show(SnackbarState.SUCCESS, 'Profile updated successfully')
    callback()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
  const user = await usersService.findCurrentUser().catch(() => null)
  if (user) setUser(user)
}

async function openMenu() {
  floatingMenu?.value?.show()
}
async function logout() {
  authLogout()
  logoutExtension()
}
</script>
