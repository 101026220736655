<template>
  <td class="w-[300px] py-4">
    <InlineTextInput
      v-model="updatedAttribute.label"
      :disabled="review.isPlanReadonly.value"
      :placeholder="updatedAttribute.label"
      class="h-full"
      editor-classes="h-full"
      multiline
      @save="editAttributeStructure()"
    />
  </td>
  <td>
    <InlineTextInput
      v-model="updatedAttribute.question"
      :disabled="review.isPlanReadonly.value"
      class="h-full"
      editor-classes="h-full"
      multiline
      @save="editAttributeStructure()"
    />
  </td>
  <td class="w-[50px]">
    <button
      v-if="!review.isPlanReadonly.value"
      class="text-red-800 bg-red-100 flex gap-1 px-3 py-0.5 rounded-full"
      @click="emit('deleteAttributeStructure', structure.id)"
    >
      Delete
    </button>
  </td>
</template>
<script setup lang="ts">
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { ref, toRaw } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { deepEqual } from '@app/utils/objects'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'

const props = defineProps<{
  structure: AttributeStructure
}>()

const emit =
  defineEmits<(e: 'deleteAttributeStructure', structureId: string) => void>()
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()

const updatedAttribute = ref<AttributeStructure>(
  structuredClone(toRaw(props.structure)),
)
async function editAttributeStructure() {
  if (deepEqual(props.structure, updatedAttribute.value)) return
  if (!updatedAttribute.value.label || !updatedAttribute.value.question) return

  try {
    loading.start()
    await review.editAttributeStructure(
      review.entity.value.id,
      updatedAttribute.value,
    )
    snackbar.show(
      SnackbarState.SUCCESS,
      'attribute structure updated successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
