<template>
  <div>
    <div class="flex bg-slate-100">
      <template v-for="step in steps" :key="'step-' + step.number">
        <button
          class="flex border-b border-black/10 pt-[6px] border-r last:border-r-0 items-center group/step whitespace-nowrap relative bg-white"
          :class="[
            step.isDisabled
              ? 'cursor-not-allowed text-black/30'
              : 'text-primary/80 hover:text-primary',
            {
              'active-tab !text-primary   border-b-transparent after:w-full after:h-1 !border-black/10 after:bg-white after:absolute after:-bottom-0.5 after:left-0':
                step.number === activeStepNumber,
            },
          ]"
          :disabled="step.isDisabled"
          @click="setActiveStep(step)"
        >
          <div
            class="absolute top-0 left-0 w-full h-[6px] bg-slate-200"
            :class="{ '!bg-primary': step.number === activeStepNumber }"
          ></div>
          <slot
            :name="`tab-${step.number}`"
            :step="step"
            :disabled="step.isDisabled"
          >
            <div class="flex items-start flex-col px-8 py-3">
              <div class="dark:text-white">{{ step.title }}</div>
              <div class="text-gray-500">{{ step.subtitle }}</div>
            </div>
          </slot>
        </button>
      </template>
    </div>

    <div
      v-if="activeStep && (activeStep.children ?? []).length > 0"
      class="flex mx-4 border-b border-b-black/10 relative"
    >
      <template
        v-for="subStep in activeStep?.children"
        :key="'substep-' + subStep.number"
      >
        <button
          class="flex items-center group/subStep whitespace-nowrap"
          :class="[
            subStep.isDisabled
              ? 'cursor-not-allowed text-black/30'
              : 'text-slate-500 hover:text-blue-500',
            {
              'active-sub-tab !text-blue-500':
                subStep.number === activeSubStepNumber,
            },
          ]"
          :disabled="subStep.isDisabled"
          @click="setActiveStep(activeStep, subStep)"
        >
          <slot
            :name="`tab-${activeStepNumber}-substep-${subStep.number}`"
            :step="subStep"
            :disabled="subStep.isDisabled"
          >
            <div class="flex items-start flex-col px-8 py-3">
              <div class="dark:text-white">{{ subStep.title }}</div>
              <div class="text-gray-500">{{ subStep.subtitle }}</div>
            </div>
          </slot>
        </button>
      </template>
      <div ref="subIndicator" class="sub-indicator"></div>
    </div>
  </div>

  <template v-for="step in steps">
    <slot
      v-if="activeStepNumber === step.number"
      :name="`step${step.number}`"
    />
    <template v-for="substep in step.children">
      <slot
        v-if="
          activeSubStepNumber === substep.number &&
          activeStepNumber === step.number
        "
        :name="`step${step.number}-substep${substep.number}`"
      />
    </template>
  </template>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from 'vue'

type StepWithoutChildren = Omit<Step, 'children'>

type Step = {
  number: number
  title?: string
  subtitle?: string
  isDisabled?: boolean
  handler?: () => void | Promise<void>
  children?: StepWithoutChildren[]
}

const props = defineProps<{
  steps: Step[]
}>()

const activeStepNumber = defineModel<number>('step', {
  default: 1,
})

const activeSubStepNumber = defineModel<number>('subStep', {
  default: 1,
})

const activeStep = computed(() =>
  props.steps.find((step) => step.number === activeStepNumber.value),
)

function setActiveStep(step: Step, subStep?: StepWithoutChildren) {
  if (step.handler) {
    step.handler()
    return
  }

  if (step.number !== activeStepNumber.value)
    activeStepNumber.value = step.number
  if (subStep) {
    if (subStep.handler) {
      subStep.handler()
      return
    }
    activeSubStepNumber.value = subStep.number
  } else activeSubStepNumber.value = 1
}

const subIndicator = ref<HTMLElement | null>(null)

const updateSubIndicator = () => {
  nextTick(() => {
    if (subIndicator.value) {
      const activeSubButton = document.querySelector('.active-sub-tab')
      if (activeSubButton) {
        const subActiveRect = activeSubButton.getBoundingClientRect()
        const subContainerRect =
          subIndicator.value.parentElement!.getBoundingClientRect()
        subIndicator.value.style.left = `${
          subActiveRect.left - subContainerRect.left
        }px`
        subIndicator.value.style.width = `${subActiveRect.width}px`
      }
    }
  })
}

watch([activeStepNumber, activeSubStepNumber], updateSubIndicator)

onMounted(() => {
  nextTick(() => {
    updateSubIndicator()
  })
})
</script>

<style scoped>
.sub-indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  @apply bg-blue-500;
  transition:
    left 0.1s ease,
    width 0.1s ease;
}
</style>
