<template>
  <div class="flex-grow h-full">
    <AttributesStructureBuilder v-model="synthesisPlan" />
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import AttributesStructureBuilder from '../AttributeStructureBuilder/AttributesStructureBuilder.vue'
import { ReviewKey } from '@app/views/Review/use-review'

const review = injectStrict(ReviewKey)

const synthesisPlan = review.synthesisPlan
</script>
