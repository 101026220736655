<template>
  <div
    @click.stop="!disabled ? startEditMode() : ''"
    @focus="!disabled ? startEditMode : ''"
  >
    <EditorContent
      v-if="isInEditMode || !$slots.default"
      class="cursor-text h-full"
      :class="{ 'w-full': isFullWidth }"
      :editor="editor"
      @keydown.esc="reset"
      @focus="startEditMode"
    />
    <div v-else>
      <slot />
    </div>
    <slot
      name="activator"
      :start-edit-mode="startEditMode"
      :is-in-edit-mode="isInEditMode"
    />
  </div>
</template>
<script lang="ts" setup>
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { onMounted, ref, watch } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import { Placeholder } from '@tiptap/extension-placeholder'
import HardBreak from '@tiptap/extension-hard-break'

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholder?: string
    multiline?: boolean
    isFullWidth?: boolean
    editorClasses?: string
    disabled?: boolean
  }>(),
  {
    modelValue: '',
    placeholder: 'empty',
    multiline: false,
    isFullWidth: false,
    editorClasses: '',
    disabled: false,
  },
)

const isInEditMode = ref<boolean>(false)

const editor = useEditor({
  extensions: [
    Document,
    Paragraph,
    Text,
    ...(props.multiline ? [HardBreak] : []),
    Placeholder.configure({
      placeholder: props.placeholder,
      showOnlyWhenEditable: false,
      emptyNodeClass:
        'first:before:text-gray-400 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none first:before:h-0',
    }),
  ],
  injectCSS: false,
  editable: false,
  onBlur: () => {
    save()
  },
  onUpdate({ editor }) {
    emit('update:modelValue', editor.getText())
  },
  editorProps: {
    attributes: {
      class:
        props.editorClasses +
        ' px-0.5 py-0.5 outline-none focus:underline underline-offset-8',
    },
    handlePaste: (view, event) => {
      if (event.clipboardData) {
        const text = event.clipboardData.getData('text/plain')
        view.dispatch(view.state.tr.insertText(text))
        return true
      }
      return false
    },
    handleKeyDown: (_, event) => {
      if (props.multiline && event.ctrlKey && event.key === 'Enter') {
        editor.value?.commands.setHardBreak()
        return true
      } else if (event.key === 'Enter') {
        save()
        return true
      }
      return false
    },
  },
})

watch(
  () => props.modelValue,
  (value) => {
    const content = editor.value?.getText()
    if (content !== value) {
      setEditorContent(value)
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  setEditorContent(props.modelValue)
})

function setEditorContent(value: string) {
  editor.value?.commands.setContent(value?.replaceAll('\n', '<br>') ?? '')
}

function startEditMode() {
  if (window.getSelection()?.toString() || isInEditMode.value) {
    return
  }
  isInEditMode.value = true
  editor.value?.setEditable(true)
  editor.value?.commands.focus(undefined, {
    scrollIntoView: false,
  })
}

const emit = defineEmits<{
  (e: 'save', value: string): void
  (e: 'update:modelValue', value: string): void
}>()

function reset() {
  isInEditMode.value = false
  editor.value?.setEditable(false)
  editor.value?.commands.blur()
  setEditorContent(props.modelValue)
}

function save() {
  isInEditMode.value = false
  emit('save', editor.value?.getText() ?? '')
  editor.value?.setEditable(false)
  editor.value?.commands.blur()
}

defineExpose({
  startEditMode,
})
</script>
