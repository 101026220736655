<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g opacity="0.9">
      <path
        d="M8.50036 10.7857C9.45053 10.7857 10.2208 9.98622 10.2208 9C10.2208 8.01378 9.45053 7.21429 8.50036 7.21429C7.55019 7.21429 6.77992 8.01378 6.77992 9C6.77992 9.98622 7.55019 10.7857 8.50036 10.7857Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.07574 9.4217C1.97467 9.1491 1.97476 8.84688 2.07598 8.57434C3.06982 5.89849 5.571 4 8.49939 4C11.4292 4 13.9314 5.90036 14.9243 8.5783C15.0253 8.85091 15.0252 9.15312 14.924 9.42566C13.9302 12.1015 11.429 14 8.50061 14C5.57078 14 3.06858 12.0996 2.07574 9.4217ZM11.2533 9C11.2533 10.578 10.0209 11.8571 8.50061 11.8571C6.98034 11.8571 5.74792 10.578 5.74792 9C5.74792 7.42204 6.98034 6.14286 8.50061 6.14286C10.0209 6.14286 11.2533 7.42204 11.2533 9Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.9"
      d="M14.1002 1.70078C14.1002 1.47987 13.9211 1.30078 13.7002 1.30078C13.4793 1.30078 13.3002 1.47987 13.3002 1.70078V2.90078H12.1002C11.8793 2.90078 11.7002 3.07987 11.7002 3.30078C11.7002 3.5217 11.8793 3.70078 12.1002 3.70078H13.3002V4.90078C13.3002 5.1217 13.4793 5.30078 13.7002 5.30078C13.9211 5.30078 14.1002 5.1217 14.1002 4.90078V3.70078H15.3002C15.5211 3.70078 15.7002 3.52169 15.7002 3.30078C15.7002 3.07987 15.5211 2.90078 15.3002 2.90078H14.1002V1.70078Z"
      fill="white"
    />
  </svg>
</template>
