<template>
  <slot
    name="activator"
    :show="() => modalManager.showModal(id)"
    @click.capture.prevent=""
  />
  <teleport to="#modal">
    <div
      v-if="modalManager.isModalVisible(id)"
      style="z-index: 9999999999"
      class="w-screen h-screen bg-black/50 fixed top-0 left-0 flex flex-wrap"
      @click="dismissible && modalManager.hideModal(id)"
    >
      <div
        class="container m-auto bg-white rounded-lg shadow-xl max-w-max max-h-screen"
        :class="props.rounded"
        @click.stop=""
      >
        <slot name="content" :hide="() => modalManager.hideModal(id)" />
      </div>
    </div>
  </teleport>
</template>
<script lang="ts" setup>
import useModalManager from './modal-manager'
import { v4 as uuid4 } from 'uuid'
import { onBeforeUnmount, onMounted } from 'vue'

const id = uuid4()
const modalManager = useModalManager()
modalManager.addModal(id)

const props = withDefaults(
  defineProps<{ dismissible?: boolean; rounded?: string }>(),
  {
    dismissible: false,
    rounded: 'rounded-sm',
  },
)

function dismissOnEsc(event: KeyboardEvent) {
  if (event.key === 'Escape' && props.dismissible) {
    modalManager.hideModal(id)
  }
}
onMounted(() => {
  document.addEventListener('keydown', dismissOnEsc)
})

onBeforeUnmount(() => document.removeEventListener('keydown', dismissOnEsc))

defineExpose({
  hide: () => modalManager.hideModal(id),
  show: () => modalManager.showModal(id),
})
</script>
